import React from 'react'
import {IconProps} from '../../store/types'

const TicketIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.01 4.46A2.81 2.81 0 0 0 2.2 7.27v9.46a2.81 2.81 0 0 0 2.81 2.81h6.15a3.63 3.63 0 0 1 6.93 0h.9a2.81 2.81 0 0 0 2.81-2.81V7.27a2.81 2.81 0 0 0-2.81-2.81h-.9a3.63 3.63 0 0 1-6.93 0H5.01ZM.2 7.27a4.81 4.81 0 0 1 4.81-4.81h7a1 1 0 0 1 1 .97 1.62 1.62 0 0 0 3.24 0 1 1 0 0 1 1-.97h1.74a4.81 4.81 0 0 1 4.81 4.81v9.46a4.81 4.81 0 0 1-4.81 4.81h-1.74a1 1 0 0 1-1-.97 1.62 1.62 0 0 0-3.25 0 1 1 0 0 1-1 .97H5.01A4.81 4.81 0 0 1 .2 16.73V7.27Zm14.2.68a1 1 0 0 1 1 1v.2a1 1 0 1 1-2 0v-.2a1 1 0 0 1 1-1Zm0 3a1 1 0 0 1 1 1v.19a1 1 0 1 1-2 0v-.2a1 1 0 0 1 1-1Zm0 3a1 1 0 0 1 1 1v.19a1 1 0 1 1-2 0v-.2a1 1 0 0 1 1-1Z">
        </path>
    </svg>
}

export default TicketIcon
