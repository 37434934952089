import React from 'react'
import {IconProps} from '../../store/types'

const ChevronUpDownIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path className="arrow-up" fillRule="evenodd" clipRule="evenodd" d="M11.3 4.1a1 1 0 0 1 1.4 0l3.6 3.6a1 1 0 0 1-1.4 1.4L12 6.22l-2.9 2.9a1 1 0 0 1-1.4-1.42l3.6-3.6Z"></path>
        <path className="arrow-down" fillRule="evenodd" clipRule="evenodd" d="M7.7 14.9a1 1 0 0 1 1.4 0l2.9 2.89 2.9-2.9a1 1 0 0 1 1.4 1.42l-3.6 3.6a1 1 0 0 1-1.4 0l-3.6-3.6a1 1 0 0 1 0-1.42Z"></path>
    </svg>
}

export default ChevronUpDownIcon
