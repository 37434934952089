import React, {useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {useTranslation} from 'react-i18next'
import {getInitializeError} from '../../store/appSlice'
import {ThemeContext} from '../../context'
import {getFromStorage} from '../../store/storage'

const Initialize = () => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const error = useSelector(getInitializeError)

    const [_, connect] = useConnectWallet()

    useEffect(() => {
        const connectedWallet = getFromStorage('connectedWallet')
        if (connectedWallet) {
            connect({autoSelect: {label: connectedWallet, disableModals: true}})
        }
    }, [])

    return <div className="wrapper" data-theme={dark ? '' : 'light'}>
        <main className="s-main page-loading">
            <div className="block-loading">
                <img className="img-loading" src="/img/logo-anim.svg" alt=""/>
                <div className={`mt-5 ${error === '' ? '' : 'text-danger'}`}>
                    {error === '' ? `${t('status.initializing')}...` : error}
                </div>
            </div>
        </main>
    </div>
}

export default Initialize
