import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {AlertElement, CheckboxElement, LabelElement} from './index'
import {IDropdownItem} from '../../store/types'

interface propsType {
    checkedList: any[]
    errorText?: string
    label?: ReactNode
    list: IDropdownItem[]
    onChange: (id: any, checked: boolean) => void
}

const CheckboxGroupElement = (props: propsType) => {
    const {t} = useTranslation()

    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        {props.list.length > 0 ?
            <div className="row gx-4">
                {props.list.map((item) => (
                    <div className="col-auto mb-2" key={item.id}>
                        <CheckboxElement
                            checked={props.checkedList.indexOf(item.id) >= 0}
                            onChange={(event) => {
                                props.onChange(item.id, event.target.checked)
                            }}
                            label={item.name}
                        />
                    </div>
                ))}
            </div>
            :
            <AlertElement>{t('status.emptyList')}</AlertElement>
        }
        {props.errorText && props.errorText !== '' ?
            <div className="text-danger">{props.errorText}</div>
            :
            null
        }
    </>
}

export default CheckboxGroupElement
