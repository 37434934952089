import React, {useContext, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getCurrentNetwork, getWalletAddress, setModalCreateShowcaseV2} from '../../store/appSlice'
import {DropdownPlaceholder, DropdownSelector} from '../elements'
import {LoginIcon} from '../icons'
import {
    getDisplayId,
    getShowcaseName,
    getShowcasesByType, requestShowcases,
    setDisplayId,
    setShowcaseName
} from '../../store/showcaseV2Slice'
import {ThemeContext} from '../../context'
import {IDropdownItem} from '../../store/types'

const SelectShowcaseV2 = () => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const currentNetwork = useSelector(getCurrentNetwork)
    const displayId = getDisplayId()
    const showcaseName = useSelector(getShowcaseName)
    const showcases = useSelector(getShowcasesByType(showcaseType), shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!showcases) {
            return
        }

        if (displayId !== null) {
            let displayFound = false
            for (let sh of showcases) {
                if (sh.id === displayId) {
                    displayFound = true
                    dispatch(setShowcaseName(sh.name))
                    break
                }
            }
            if (!displayFound) {
                setDisplayId(null)
                dispatch(setShowcaseName(null))
            }
        } else if (showcases.length > 0) {
            setDisplayId(showcases[0].id)
            dispatch(setShowcaseName(showcases[0].name))
        }
    }, [showcases, showcaseName, walletAddress])
    useEffect(() => {
        if (!currentNetwork) {
            return
        }

        if (walletAddress) {
            dispatch(requestShowcases())
        }
    }, [walletAddress, currentNetwork])

    const addDisplayHandler = () => {
        if (!showcaseType) {
            return
        }

        dispatch(setModalCreateShowcaseV2({type: showcaseType}))
    }
    const changeShowcaseHandler = (id: number) => {
        setDisplayId(id)
        dispatch(setShowcaseName(''))
    }

    const displayList: IDropdownItem[] = showcases?.map((item): IDropdownItem => {
        return {id: item.id, name: item.name}
    }) || []

    return <div className="row gx-3">
        <div className="col-8 col-md-4 col-xl-3">
            <div className="mb-3">
                {showcases ?
                    <DropdownSelector
                        list={displayList}
                        setItem={changeShowcaseHandler}
                        currentItem={displayId}
                        firstItem={{id: 0, name: t('button.addShowcase')}}
                        firstItemAction={addDisplayHandler}
                    />
                    :
                    <DropdownPlaceholder title={`${t('status.loading')}...`}/>
                }
            </div>
        </div>
        <div className="col-2 col-md-auto">
            {currentNetwork && showcaseName ?
                <Link
                    to={`/showcases/${Number(currentNetwork)}/${showcaseName}`}
                    className={'btn btn-link w-100 px-2'}
                >
                    <LoginIcon className={'me-md-2'}/>
                    <span className="d-none d-md-inline">{t('button.goTo', {name: showcaseName})}</span>
                </Link>
                :
                null
            }
        </div>
    </div>
}

export default SelectShowcaseV2
