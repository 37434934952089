import React, {ReactNode} from 'react'
import {LoadingIcon} from '../icons'

interface propsType {
    checked: boolean
    disabled?: boolean
    label?: ReactNode
    loading?: boolean
    name?: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

const CheckboxElement = (props: propsType) => {
    return <label className="form-check mt-1">
        <input
            type="checkbox"
            className="form-check-input"
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled || props.loading}
        />
        <div className="form-check-checkbox"></div>
        {props.label ?
            <div className="form-check-label">{props.label}</div>
            :
            null
        }
        {props.loading ? <LoadingIcon/> : null}
    </label>
}

export default CheckboxElement
