import React, {ReactNode} from 'react'
import DatePicker from 'react-datepicker'
import {LabelElement} from './index'

interface propsType {
    additionalClass?: string
    error?: boolean
    errorText?: string
    label?: ReactNode
    onChange: (value: Date | null) => void
    placeholder?: string
    readonly?: boolean
    selectsRange?: false
    setError?: (text: string) => void
    value: Date | null
}
interface propsTypeRange {
    additionalClass?: string
    error?: boolean
    errorText?: string
    label?: ReactNode
    onChange: (value: [Date | null, Date | null]) => void
    placeholder?: string
    readonly?: boolean
    selectsRange: true
    setError?: (text: string) => void
    value: [Date | null, Date | null]
}

const DateElement = (props: propsType | propsTypeRange) => {
    const error = props.errorText && props.errorText !== '' ? true : props.error

    return <>
        {props.label ? <LabelElement>{props.label}</LabelElement> : null}
        <div className={error ? 'is-invalid' : ''}>
            <DatePicker
                isClearable
                className={`form-control ${error ? 'is-invalid' : ''} ${props.additionalClass || ''}`}
                dateFormat="yyyy/MM/dd HH:mm"
                endDate={props.selectsRange ? props.value[1] : undefined}
                onChange={props.onChange}
                placeholderText={props.placeholder}
                selected={!props.selectsRange ? props.value : undefined}
                selectsRange={props.selectsRange}
                showTimeSelect={!props.selectsRange}
                startDate={props.selectsRange ? props.value[0] : undefined}
            />
        </div>
        {props.errorText && props.errorText !== '' ?
            <div className="invalid-feedback">{props.errorText}</div>
            :
            null
        }
    </>
}

export default DateElement
