import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {MintBlock, SelectMintCollection} from './index'
import {getCollections, mintNftInCollection, resetState} from '../../store/mintSlice'
import {AppDispatch} from '../../store/store'
import {getSelectedMintCollection, getWalletAddress, setModalError} from '../../store/appSlice'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {getSelectedTicketLevelName} from '../../store/ticketsSlice'
import {ThemeContext} from '../../context'
import {VideoButton} from '../elements'
import {
    getBatch,
    getDescription, getImageUrl, getLoadedImage,
    getProperties,
    getTitle,
    setBatchError,
    setDescriptionError, setImageUrlError, setTitleError
} from '../../store/inputSlice'

const MintCollectionPage = () => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const collections = useSelector(getCollections)
    const eventName = useSelector(getSelectedEventName)
    const imageUrl = useSelector(getImageUrl)
    const loadedImage = useSelector(getLoadedImage)
    const organizerName = useSelector(getSelectedOrganizerName)
    const properties = useSelector(getProperties)
    const selectedCollection = useSelector(getSelectedMintCollection)
    const ticketBatch = useSelector(getBatch)
    const ticketDescription = useSelector(getDescription)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketName = useSelector(getTitle)
    const walletAddress = useSelector(getWalletAddress)

    const [{wallet}] = useConnectWallet()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(resetState())
        }
    }, [walletAddress])

    if (!showcaseType) {
        return null
    }

    const submitHandler = () => {
        let error = false
        if (imageUrl.value === null) {
            error = true
            dispatch(setImageUrlError(t('error.chooseImage')))
        } else {
            dispatch(setImageUrlError(null))
        }
        if (organizerName === '' || eventName === '' || ticketLevelName === '') {
            error = true
        }
        if (!ticketBatch.value) {
            error = true
            dispatch(setBatchError(t('error.mustEnter', {name: t('form.label.batchAmount')})))
        }
        if (ticketDescription.value === '') {
            error = true
            dispatch(setDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        }
        if (ticketName.value === '') {
            error = true
            dispatch(setTitleError(t('error.mustEnter', {name: t('form.label.ticketName')})))
        }
        if (properties.error.status) {
            error = true
        }
        if (!wallet) {
            dispatch(setModalError({text: t('walletNotConnected'), buttons: ['close']}))
            return
        }

        if (error) {
            return
        }

        if (loadedImage) {
            dispatch(mintNftInCollection(showcaseType))
        } else {
            dispatch(setModalError({text: t('error.imageLoadingError'), buttons: ['close']}))
        }
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
                    <h1>{t('header.mintTickets')}</h1>
                </div>
                <div className="col-auto">
                    <VideoButton
                        link={'https://www.youtube.com/embed/fkO68Cs__tI?si=z3gpDMidQQUifQBP'}
                        title={t('tutorial.title.mint')}
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-8">
                    <p><Trans i18nKey={`mint.collectionPageDescription${showcaseType}1`} components={[<strong/>]}/></p>
                    <p>
                        <Trans
                            i18nKey={`mint.collectionPageDescription${showcaseType}2`}
                            components={[<Link to={`/${showcaseType === 'classic' ? 'smart' : 'classic'}/mint`}/>]}
                        />
                    </p>
                </div>
            </div>
            <SelectMintCollection/>
            {collections && selectedCollection ?
                <MintBlock maxBatchAmount={100} onSubmit={submitHandler}/>
                :
                null
            }
        </div>
    </div>
}

export default MintCollectionPage
