import React from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {delShowcaseFromEvent, getCurrentEventId, getEvent} from '../../store/eventsSlice'
import {AppDispatch} from '../../store/store'
import {getWalletAddress, setModalConfirmation} from '../../store/appSlice'
import {CHAINS} from '../../utils/constants'
import {CrossIcon, TagIcon} from '../icons'
import {ButtonElement} from '../elements'
import {DBShowcase} from '../../store/types'

interface ParamsType {
    showcase: DBShowcase
}

const ShowcaseRow = (params: ParamsType) => {
    const {t} = useTranslation()
    const {showcase} = params
    const currentEventId = useSelector(getCurrentEventId)
    const event = useSelector(getEvent(currentEventId))
    const walletAddress = useSelector(getWalletAddress)
    let showcaseUrl = ''
    switch (showcase.contract) {
        case CHAINS[`0x${showcase.chain.toString(16)}`].launchpadContract:
            showcaseUrl = 'showcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].lazyMintingContract:
            showcaseUrl = 'lazyshowcase'
            break
        case CHAINS[`0x${showcase.chain.toString(16)}`].showcaseV2Contract:
            showcaseUrl = 'showcases'
            break
    }

    const dispatch = useDispatch<AppDispatch>()

    const delShowcaseHandler = (showcaseId: number) => {
        let name = ''
        for (let item of event?.showcases || []) {
            if (item.id === showcaseId) {
                name = item.name
                break
            }
        }
        if (name === '' || !currentEventId) {
            return
        }

        dispatch(setModalConfirmation({
            title: t('modal.question.deleteShowcaseFromEvent', {name}),
            confirmAction: () => {
                dispatch(delShowcaseFromEvent({eventId: currentEventId, showcaseId}))
            },
        }))
    }

    return <div className="row-table__item mb-2 py-0 px-1">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="col">
                {showcaseUrl !== '' ?
                    <Link
                        to={`/${showcaseUrl}/${showcase.chain}/${showcase.name}`}
                        className="btn btn-link w-100 justify-content-start"
                    >
                        <TagIcon className={'me-3'}/>
                        <span> <b>{showcase.name} ({showcase.title} tickets)</b></span>
                    </Link>
                    :
                    <div className="btn w-100 justify-content-start disabled border-0">
                        <TagIcon className={'me-3'}/>
                        <span> <b>{t('error.showcaseNotFound')}</b></span>
                    </div>
                }
            </div>
            <div className="col-auto">
                {event?.organizer === walletAddress ?
                    <ButtonElement
                        className={'py-2 btn-with-opacity'}
                        onClick={() => delShowcaseHandler(showcase.id)}
                    >
                        <CrossIcon/>
                    </ButtonElement>
                    :
                    null
                }
            </div>
        </div>
    </div>
}

export default ShowcaseRow
