import React from 'react'
import {IconProps} from '../../store/types'

const SignPencil = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.26 4.01a2.2 2.2 0 0 1 3.1 0l2.08 2.07a2.2 2.2 0 0 1 0 3.11l-9.78 9.78c-.3.3-.7.52-1.12.6l-4.36.88A1 1 0 0 1 2 19.27l.88-4.36c.09-.42.3-.81.6-1.12l9.78-9.78Zm1.7 1.42a.2.2 0 0 0-.29 0L4.9 15.2a.2.2 0 0 0-.06.1l-.58 2.88 2.89-.58a.2.2 0 0 0 .1-.05l9.77-9.78a.2.2 0 0 0 0-.29l-2.07-2.06Zm.75 13.78a1.06 1.06 0 0 0-1.68-.13l-.75.83a1 1 0 1 1-1.49-1.33l.74-.83a3.06 3.06 0 0 1 4.87.4c.35.54 1.12.65 1.6.21l1.35-1.2a1 1 0 0 1 1.34 1.48l-1.35 1.2a3.06 3.06 0 0 1-4.63-.63Z"></path>
    </svg>
}

export default SignPencil
