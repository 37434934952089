import React, {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getCurrentNetwork, getModalMintGifts, setModalMintGifts} from '../../store/appSlice'
import {
    getMintedTokenId,
    getNftJsonUrl,
    getNftTxId,
    getNftTxSigned,
    resetMintState, setMintedTokenId
} from '../../store/mintSlice'
import {CHAINS, ENVELOP_DASHBOARD} from '../../utils/constants'
import {ExternalLinkIcon, SuccessIcon} from '../icons'
import {ButtonElement} from '../elements'
import {
    getApproveCoinCount,
    getApprovedCoinCount,
    getApprovedNfts, getGiftsTxId,
    getMintedGiftsCount, getMintedGiftsIds, getSelectedCollection,
    resetMintGiftsState
} from '../../store/giftsSlice'
import {getPublicCollectionDropdown} from '../../utils/functions'

interface propsType {
    show: boolean
}

const ModalMintGifts = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const approveCount = useSelector(getApproveCoinCount)
    const approvedCount = useSelector(getApprovedCoinCount)
    const approvedNfts = useSelector(getApprovedNfts)
    const currentNetwork = useSelector(getCurrentNetwork)
    const giftsTxId = useSelector(getGiftsTxId)
    const mintedGiftsCount = useSelector(getMintedGiftsCount)
    const mintedGiftIds = useSelector(getMintedGiftsIds)
    const mintedTokenId = useSelector(getMintedTokenId)
    const modal = useSelector(getModalMintGifts)
    const nftJsonUrl = useSelector(getNftJsonUrl)
    const nftTxId = useSelector(getNftTxId)
    const nftTxSigned = useSelector(getNftTxSigned)
    const selectedCollection = useSelector(getSelectedCollection)
    const publicDropdown = getPublicCollectionDropdown()
    let collectionContract = selectedCollection
    if (selectedCollection === publicDropdown.id && currentNetwork) {
        collectionContract = CHAINS[currentNetwork].wnftPublic721
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setMintedTokenId(null))
    }, [])

    if (!currentNetwork || !modal) {
        return null
    }

    const closeModal = () => {
        dispatch(setModalMintGifts(false))
        dispatch(resetMintState())
        dispatch(resetMintGiftsState())
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Progress steps"
        aria-hidden="true"
        style={{display: 'block'}}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.mintGifts')}</h2>
                </div>
                <div className="modal-body">
                    <div>
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col`}>
                                    {!nftJsonUrl ? t('status.metadataSaving') : t('status.metadataSaved')}
                                </div>
                                <div className="col-auto">
                                    {nftJsonUrl ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="visually-hidden">{t('status.loading')}...</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col ${!nftJsonUrl ? 'text-muted' : ''}`}>
                                    {!nftTxSigned ?
                                        t('status.signingMintNft')
                                        :
                                        nftTxId ? t('status.mintedNft', {name: (mintedTokenId || []).join(', ')}) : t('status.mintingNft')
                                    }
                                </div>
                                <div className="col-auto">
                                    {nftTxId ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        nftJsonUrl ?
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">{t('status.loading')}...</span>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col ${!nftTxId ? 'text-muted' : ''}`}>
                                    {approvedNfts ?
                                        t('status.approved')
                                        :
                                        t('action.setApprovalForMintGifts')
                                    }
                                </div>
                                <div className="col-auto">
                                    {approvedNfts ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        nftTxId ?
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">{t('status.loading')}...</span>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        {approveCount ?
                            <div className="progress-step">
                                <div className="row align-items-center">
                                    <div className={`col ${!approvedNfts ? 'text-muted' : ''}`}>
                                        {/*todo: delete checking condition*/}
                                        {approveCount === null ?
                                            t('status.settingApproves')
                                            :
                                            `${approveCount > approvedCount ?
                                                t('status.settingApproves')
                                                :
                                                t('status.approved')
                                            } (${approvedCount}/${approveCount})`

                                        }
                                    </div>
                                    <div className="col-auto">
                                        {/*todo: simplify conditions*/}
                                        {approveCount !== null && approvedCount === approveCount ?
                                            <SuccessIcon className={'icon-success'}/>
                                            :
                                            approveCount !== null ?
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">{t('status.loading')}...</span>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="progress-step">
                            <div className="row align-items-center">
                                <div className={`col ${approveCount === null || approveCount < approvedCount ? 'text-muted' : ''}`}>
                                    {mintedGiftsCount === null ?
                                        t('status.mintingGifts')
                                        :
                                        t('status.mintedGifts', {name: mintedGiftsCount})
                                    }
                                </div>
                                <div className="col-auto">
                                    {mintedGiftsCount !== null ?
                                        <SuccessIcon className={'icon-success'}/>
                                        :
                                        approveCount !== null && approvedCount === approveCount ?
                                            <div className="spinner-border text-warning" role="status">
                                                <span className="visually-hidden">{t('status.loading')}...</span>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-between">
                    <div className="col-12 col-lg mb-3 mb-lg-0">
                        {giftsTxId ?
                            <>
                                <a
                                    className="icon-link"
                                    href={`${CHAINS[currentNetwork].blockExplorer}/tx/${giftsTxId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{t('word.transaction')}</span>
                                    <ExternalLinkIcon/>
                                </a>
                                <br/>
                                <a
                                    className="icon-link"
                                    href={`${ENVELOP_DASHBOARD}/${parseInt(currentNetwork, 16)}/${collectionContract}/${mintedGiftIds?.[0]}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>{t('button.showDetails')}</span>
                                    <ExternalLinkIcon/>
                                </a>
                            </>
                            :
                            null
                        }
                    </div>
                    <div className="col-12 col-lg-auto">
                        <div className="row gx-3">
                            {mintedGiftsCount !== null ?
                                <div className="col-sm-6 col-lg-auto order-md-2 mb-3 mb-lg-0">
                                    <ButtonElement
                                        additionalClass={'w-100'}
                                        onClick={closeModal}
                                    >{t('button.moreGifts')}</ButtonElement>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalMintGifts
