import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getEvents, requestEventsWithFilter, setEvents} from '../../store/eventsSlice'
import {AlertElement, ButtonElement, DropdownSelector, NoteElement} from '../elements'
import {EVENT_TYPE} from '../../utils/constants'
import {EventCardWithLink} from './index'
import {getWalletAddress, setModalCreateEvent} from '../../store/appSlice'
import {DBEventWithOrganizer, IDropdownItem} from '../../store/types'
import {LoadingIcon} from '../icons'
import {ThemeContext} from '../../context'
import {getEventTypeList, getMonthList} from '../../utils/functions'
import {SelectOrganizer} from '../profile'
import {getFromStorage, setToStorage} from '../../store/storage'

const Events = () => {
    const {t} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const [hideNote, setHideNote] = useState(getFromStorage('hideEventsNote'))
    const [searchParams, setSearchParams] = useSearchParams('')
    const currentYear = (new Date()).getFullYear()
    const [firstEventsRequest, setFirstEventsRequest] = useState(true)
    const [selectedMonth, setSelectedMonth] = useState(-1)
    const [selectedYear, setSelectedYear] = useState(-1)
    const [eventType, setEventType] = useState(EVENT_TYPE.All)
    let yearsArray: IDropdownItem[] = [{id: -1, name: t('word.all')}]
    for (let i = 2022; i < currentYear + 3; i++) {
        yearsArray.push({id: i, name: i.toString()})
    }
    let monthArray: IDropdownItem[] = [{id: -1, name: t('word.all')}, ...getMonthList()]
    const events = useSelector(getEvents)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (firstEventsRequest) {
            setFirstEventsRequest(false)
            return
        }

        if (!events) {
            dispatch(requestEventsWithFilter({month: selectedMonth, year: selectedYear}))
        }
    }, [events])
    useEffect(() => {
        if (events) {
            dispatch(setEvents(null))
        }

        searchParams.set('month', selectedMonth.toString())
        searchParams.set('year', selectedYear.toString())
        setSearchParams(searchParams)
        dispatch(requestEventsWithFilter({month: selectedMonth, year: selectedYear}))
    }, [selectedMonth, selectedYear])
    useEffect(() => {
        switch (showcaseType) {
            case 'classic':
                setEventType(EVENT_TYPE.Classic)
                break
            case 'smart':
                setEventType(EVENT_TYPE.Smart)
                break
            default:
                setEventType(EVENT_TYPE.All)
        }
    }, [showcaseType])

    const eventsList = useMemo(() => {
        if (events === null) {
            return <LoadingIcon/>
        }

        let filtered: DBEventWithOrganizer[] = []
        for (let event of events) {
            if (event.organizer === walletAddress &&
                (event.showcaseType === EVENT_TYPE[eventType] || eventType === EVENT_TYPE.All)
            ) {
                filtered.push(event)
            }
        }
        if (filtered.length === 0) {
            return <AlertElement centered>{t('status.noEvents')}</AlertElement>
        }

        return <div className="row">
            {filtered.map((item) => (
                <EventCardWithLink event={item} link={`/event/${item.organizerUrl}/${item.url}`} key={item.id}/>
            ))}
        </div>
    }, [events, eventType, walletAddress])

    if (!showcaseType) {
        return null
    }

    const addEventHandler = () => {
        dispatch(setModalCreateEvent(true))
    }
    const hideHandler = () => {
        setHideNote('hide')
        setToStorage('hideEventsNote', 'hide')
    }

    return <div className="page-content">
        <div className="container">
            <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
            <h1>{t(`header.${showcaseType}Events`)}</h1>
            {!hideNote ? <NoteElement crossAction={hideHandler}>{t('event.note')}</NoteElement> : null}
            <div className="row">
                <div className="col-9 col-md-8 col-lg-6 col-xl-5">
                    <div className="mb-3"><SelectOrganizer/></div>
                </div>
            </div>
            <div className="row gx-2 gx-md-3 mb-3">
                <div
                    className="col-md-auto me-md-0 ms-md-auto mb-4 mb-md-0 pb-4 pb-md-0 mt-n3 mt-md-0 order-md-4">
                    <ButtonElement small additionalClass={'w-100'} onClick={addEventHandler}>
                        {t('button.addEvent')}
                    </ButtonElement>
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedYear}
                        list={yearsArray}
                        setItem={setSelectedYear}
                        button
                    />
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedMonth}
                        list={monthArray}
                        setItem={setSelectedMonth}
                        button
                    />
                </div>
                <div className="col-auto mb-2 mb-xl-3">
                    <DropdownSelector
                        currentItem={eventType}
                        list={getEventTypeList()}
                        setItem={setEventType}
                        button
                    />
                </div>
            </div>
            {eventsList}
        </div>
    </div>
}

export default Events
