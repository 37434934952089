import React from 'react'
import {IconProps} from '../../store/types'

const MediumIcon = (props: IconProps) => {
    return <svg className={props.className} width="25" height="25" viewBox="0 0 25 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5 25a12.5 12.5 0 1 0 0-25 12.5 12.5 0 0 0 0 25Zm-3.1-6.43c.36.17.67 0 .67-.42 0-9.35.04-9.49-.01-9.53h-.01L5.9 6.54c-.46-.23-.67-.07-.67.27v9.23c0 .12.04.25.12.37.08.13.17.22.29.27l3.77 1.89Zm5.53-2.09-4.34-7v4.85l4.34 2.15Zm4.84 1.67V9.62l-4.38 7.1 3.58 1.78c.12.07.25.1.38.1a.4.4 0 0 0 .3-.12c.08-.09.12-.2.12-.33Zm-2.1-6.1 2.08-3.4c0-.02-.01-.04-.04-.05L15.32 6.4a.5.5 0 0 0-.63.18l-2.63 4.27 3.17 5.13 2.44-3.95Z"></path>
    </svg>
}

export default MediumIcon
