import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {sendRequestWithAuth, setRedirectPath} from '../../store/appSlice'
import {
    ButtonElement,
    CheckboxGroupElement,
    DateElement,
    InputElement,
    TextareaElement
} from '../elements'
import {
    addEvent,
    editEvent,
    getEvent,
    getEventTypesList,
    requestEvent,
    requestEventTypes,
    setEvent
} from '../../store/calendarSlice'
import {getUser} from '../../store/authSlice'
import {ILink} from '../../store/types'
import {checkUrl} from '../../utils/functions'

const AddOrEditCalendarEvent = () => {
    const {t} = useTranslation()
    const {eventId} = useParams()
    const [checkedTypes, setCheckedTypes] = useState<number[]>([])
    const [checkedTypesError, setCheckedTypesError] = useState('')
    const [eventTitle, setEventTitle] = useState('')
    const [eventTitleError, setEventTitleError] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [startDateError, setStartDateError] = useState('')
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [endDateError, setEndDateError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [links, setLinks] = useState<ILink[]>([])
    const [linksError, setLinksError] = useState('')
    const event = useSelector(getEvent)
    const typeList = useSelector(getEventTypesList)
    const user = useSelector(getUser)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestEventTypes())
        return () => {
            if (event) {
                dispatch(setEvent(null))
            }
        }
    }, [])
    useEffect(() => {
        if (event) {
            setEventTitle(event.title)
            setDescription(event.description)
            setStartDate(event.startTime)
            setEndDate(event.endTime)
            setCheckedTypes(event.types.map(item => item.id))
            setLinks(event.links)
        } else {
            setEventTitle('')
            setDescription('')
            setStartDate(new Date())
            setEndDate(new Date(Date.now() + 3600000))
            setCheckedTypes([])
            setLinks([{title: '', url: ''}])
            if (eventId) {
                dispatch(requestEvent(Number(eventId)))
            }
        }
    }, [event])
    useEffect(() => {
        if (!user || !user.manager) {
            dispatch(setRedirectPath('/calendar'))
        }
    }, [user])

    const addLink = () => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState.push({title: '', url: ''})
            return newState
        })
    }
    const deleteLink = (index: number) => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState.splice(index, 1)
            return newState
        })
    }
    const editLink = (index: number, title?: string, url?: string) => {
        setLinks((prevState) => {
            let newState = [...prevState]
            newState[index] = {...prevState[index]}
            if (title !== undefined) {
                newState[index].title = title
            }
            if (url !== undefined) {
                newState[index].url = url
            }
            return newState
        })
    }
    const eventTypesHandler = (id: any, checked: boolean) => {
        if (!checked) {
            setCheckedTypes(checkedTypes.filter((item) => item !== id))
        } else if (checkedTypes.indexOf(id) < 0) {
            setCheckedTypes([...checkedTypes, id])
        }
    }
    const submitHandler = () => {
        let error = false
        const start = startDate ? startDate.getTime() : 0
        const end = endDate ? endDate.getTime() : 0

        if (eventTitle.trim() === '') {
            error = true
            setEventTitleError(t('error.titleEmpty'))
        } else {
            setEventTitleError('')
        }
        if (start <= 0) {
            error = true
            setStartDateError(t('error.wrong', {name: t('form.label.startDate')}))
        } else {
            setStartDateError('')
        }
        if (end <= 0) {
            error = true
            setEndDateError(t('error.wrong', {name: t('form.label.endDate')}))
        } else if (end < start) {
            error = true
            setEndDateError(t('error.mustBeGreater', {name: t('form.label.endDate'), value: t('form.label.startDate')}))
        } else {
            setEndDateError('')
        }
        if (description.trim() === '') {
            error = true
            setDescriptionError(t('error.descriptionEmpty'))
        } else {
            setDescriptionError('')
        }
        if (!checkedTypes.length) {
            error = true
            setCheckedTypesError(t('error.typeNotSelected'))
        } else {
            setCheckedTypesError('')
        }
        let linkError = false
        for (let item of links) {
            if (item.title === '' && item.url === '') {
                continue
            }
            if (item.title === '' || item.url === '') {
                error = true
                linkError = true
                setLinksError(t('error.mustEnter', {name: t('form.label.links')}))
                break
            }
            if (!checkUrl(item.url)) {
                error = true
                linkError = true
                setLinksError(t('error.wrong', {name: t('form.label.url')}))
                break
            }
        }
        if (!linkError) {
            setLinksError('')
        }

        if (error) {
            return
        }

        if (event) {
            dispatch(sendRequestWithAuth(editEvent({
                description,
                endDate: end,
                links: links.filter((item) => item.title.trim() !== '' || item.url.trim() !== ''),
                startDate: start,
                title: eventTitle,
                typeIds: checkedTypes,
            })))
        } else {
            dispatch(sendRequestWithAuth(addEvent({
                description,
                endDate: end,
                links: links.filter((item) => item.title.trim() !== '' || item.url.trim() !== ''),
                startDate: start,
                title: eventTitle,
                typeIds: checkedTypes,
            })))
        }
    }

    if (!user || !user.manager) {
        return null
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/calendar'}>{t('section.calendar')}</Link></li>
                    {event ?
                        <li className="breadcrumb-item">
                            <Link to={`/calendar/events/${event.id}`}>{event.title}</Link>
                        </li>
                        :
                        null
                    }
                    <li className="breadcrumb-item active" aria-current="page">
                        {event ? t('section.editingEvent') : t('section.addingEvent')}
                    </li>
                </ol>
            </nav>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                    <div className="mb-3 pb-4">
                        <h1 className="mb-1">{t('header.thirdPartyEvent')}</h1>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    errorText={eventTitleError}
                                    label={t('form.label.title')}
                                    value={eventTitle}
                                    onChange={setEventTitle}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <TextareaElement
                                    errorText={descriptionError}
                                    label={t('form.label.description')}
                                    value={description}
                                    onChange={setDescription}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <DateElement
                                    value={startDate}
                                    onChange={setStartDate}
                                    errorText={startDateError}
                                    label={t('form.label.startDate')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-4">
                                <DateElement
                                    value={endDate}
                                    onChange={setEndDate}
                                    errorText={endDateError}
                                    label={t('form.label.endDate')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <CheckboxGroupElement
                                    checkedList={checkedTypes}
                                    list={typeList}
                                    onChange={eventTypesHandler}
                                    label={t('form.label.type')}
                                    errorText={checkedTypesError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-body pb-4">
                        <div className="row gx-2 gx-lg-3">
                            <div className="col-12 col-lg-4">
                                <label className="form-label">{t('form.label.links')}</label>
                            </div>
                        </div>
                        {linksError !== '' ? <div className="text-danger">{linksError}</div> : null}
                        <div className="row gx-2 gx-lg-3">
                        <div className="col-6 col-lg-4">
                                <label className="form-label">{t('form.label.name')}</label>
                            </div>
                            <div className="col-6 col-lg-6">
                                <label className="form-label">{t('form.label.url')}</label>
                            </div>
                        </div>
                        {links?.map((data, index) => {
                            const {title, url} = data
                            return <div className="row gx-2 gx-lg-3" key={index}>
                                <div className="col-6 col-lg-4">
                                    <div className="mb-lg-3">
                                        <InputElement
                                            value={title}
                                            placeholder={t('form.label.name')}
                                            onChange={(value) => {
                                                editLink(index, value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-lg-6">
                                    <div className="mb-lg-3">
                                        <InputElement
                                            value={url}
                                            placeholder={t('form.label.url')}
                                            onChange={(value) => {
                                                editLink(index, undefined, value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto col-lg-2">
                                    <div className="mb-2 mb-lg-3">
                                        <button
                                            className="btn btn-link link-danger w-100 px-0"
                                            onClick={() => {
                                                deleteLink(index)
                                            }}
                                        >{t('button.delete')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })}
                        <ButtonElement link additionalClass="px-0" onClick={addLink}>
                            {t('button.addLink')}
                        </ButtonElement>
                    </div>
                    <div className="row mt-3 mt-lg-6">
                        <div className="col-lg-4 offset-lg-8">
                            <ButtonElement
                                additionalClass={'w-100'}
                                onClick={submitHandler}
                            >{event ? t('button.save') : t('button.addEvent')}</ButtonElement>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AddOrEditCalendarEvent
