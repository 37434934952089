import React from 'react'
import {IconProps} from '../../store/types'

const TipIcon = (props: IconProps) => {
    return <svg className={props.className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM7.84956 8.05982L7.77188 8.97902H6.49018L6.4125 7.16651C8.03081 6.86875 8.60045 6.2991 8.60045 5.53526C8.60045 4.79732 8.16027 4.39598 7.44822 4.39598C6.83973 4.39598 6.25714 4.56428 5.55804 5.06919L4.78125 3.78749C5.81697 3.15312 6.61964 2.90714 7.5 2.90714C9.39018 2.90714 10.2188 4.16294 10.2188 5.4058C10.2188 6.80401 9.20893 7.63259 7.84956 8.05982ZM7.09866 12.2804C6.51607 12.2804 6.05 11.8013 6.05 11.2317C6.05 10.6621 6.51607 10.196 7.09866 10.196C7.68125 10.196 8.14732 10.6621 8.14732 11.2317C8.14732 11.8013 7.68125 12.2804 7.09866 12.2804Z"/>
    </svg>
}

export default TipIcon
