import React, {ReactNode} from 'react'

interface propsType {
    additionalClass?: string
    children: ReactNode
}

const LabelElement = (props: propsType) => {
    return <label className={`form-label ${props.additionalClass || ''}`}>{props.children}</label>
}

export default LabelElement
