import React from 'react'
import {IconProps} from '../../store/types'

const PlayIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.4 6A4.6 4.6 0 0 1 6 1.4h12A4.6 4.6 0 0 1 22.6 6v12a4.6 4.6 0 0 1-4.6 4.6H6A4.6 4.6 0 0 1 1.4 18V6ZM6 3.4A2.6 2.6 0 0 0 3.4 6v12A2.6 2.6 0 0 0 6 20.6h12a2.6 2.6 0 0 0 2.6-2.6V6A2.6 2.6 0 0 0 18 3.4H6Zm2.86 2.95c.63-.3 1.29-.12 1.76.18l.03.02 5.5 4.05c.44.27.98.75.98 1.5s-.54 1.25-.97 1.54l-5.48 3.83-.05.03c-.44.24-1.13.47-1.79.12-.67-.36-.86-1.07-.89-1.6v-.03l-.02-8c-.01-.65.26-1.32.93-1.64ZM9.93 8.5l4.91 3.62-4.89 3.42-.02-7.04Z"></path>
    </svg>
}

export default PlayIcon
