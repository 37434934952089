import React from 'react'
import {IconProps} from '../../store/types'

const LinkIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M15.91 14.52a1 1 0 0 0 1.42 0l2.23-2.24a5.43 5.43 0 0 0-.05-7.76 5.54 5.54 0 0 0-7.75-.04L9.52 6.7a1 1 0 0 0 1.42 1.42l2.23-2.24a3.54 3.54 0 0 1 4.93.05l.01.01a3.45 3.45 0 0 1 .04 4.92L15.9 13.1a1 1 0 0 0 0 1.42Zm-2.85 1.38-2.23 2.23c-.64.64-1.5 1-2.45.99a3.45 3.45 0 0 1-2.5-1.07 3.45 3.45 0 0 1-.03-4.92L8.1 10.9a1 1 0 1 0-1.42-1.42l-2.23 2.24a5.43 5.43 0 0 0 .04 7.75 5.45 5.45 0 0 0 7.76.08l2.24-2.24a1 1 0 0 0-1.42-1.4Zm-5.15.13a1 1 0 0 0 1.41 0l6.7-6.7a1 1 0 0 0-1.41-1.41l-6.7 6.7a1 1 0 0 0 0 1.41Z"></path>
    </svg>
}

export default LinkIcon
