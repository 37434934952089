import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ConnectWalletButton} from '../wallet'
import {setMainPageClass} from '../../store/appSlice'

const WalletNotConnected = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setMainPageClass('page-404'))
        return () => {
            dispatch(setMainPageClass(null))
        }
    }, [])

    return <div className="page-content">
        <svg className="bg-404" width="706" height="745" viewBox="0 0 706 745" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="m107.43.61 597.62 160.13L598.23 559.4.61 399.26z"></path>
            <path d="m502.02 321.61 92.13 343.81-293.41 78.62-92.13-343.81z"></path>
            <path d="M.6 160.74 598.24.61l106.81 398.65-597.61 160.13z"></path>
        </svg>
        <div className="container">
            <div className="text-muted h3">{t('walletNotConnected.title')}</div>
            <h1>{t('walletNotConnected.text')}</h1>
            <div className="row">
                <div className="col-auto"><ConnectWalletButton/></div>
                <div className="col-auto">
                    <Link className={'btn btn-outline-primary'} to={'/'}>{t('walletNotConnected.homeLink')}</Link>
                </div>
            </div>
        </div>
    </div>
}

export default WalletNotConnected
