import React from 'react'
import {IconProps} from '../../store/types'

const SbtIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.16 16.63a.61.61 0 0 1-.45-.19.61.61 0 0 1-.18-.45V7.11h1.27v8.88c0 .18-.07.33-.19.45a.61.61 0 0 1-.45.18ZM16.9 8.28a.6.6 0 0 1-.43-.16.6.6 0 0 1-.16-.43.56.56 0 0 1 .59-.58h6.52c.17 0 .3.06.4.17.12.1.18.24.18.41a.56.56 0 0 1-.58.58H16.9ZM9.55 16.63a.61.61 0 0 1-.46-.19.61.61 0 0 1-.18-.45V7.74c0-.18.06-.33.18-.45a.61.61 0 0 1 .46-.18h2.9c.56 0 1.04.1 1.46.33a2.38 2.38 0 0 1 1.35 2.2c0 .4-.11.76-.33 1.09-.21.31-.5.57-.88.75.54.13.97.39 1.3.77.32.38.48.88.48 1.5a2.75 2.75 0 0 1-1.5 2.5c-.47.25-1 .38-1.62.38H9.55Zm.63-1.17h2.53c.38 0 .7-.07.99-.2.28-.13.5-.32.67-.57.16-.25.24-.57.24-.94 0-.35-.08-.64-.24-.87-.16-.22-.39-.4-.67-.5a2.49 2.49 0 0 0-.99-.19h-2.53v3.27Zm0-4.44h2.28c.46 0 .84-.11 1.13-.34.3-.23.45-.57.45-1.04 0-.46-.15-.8-.45-1.02a1.8 1.8 0 0 0-1.13-.34h-2.28v2.74ZM3.55 16.75c-.52 0-1.02-.08-1.5-.25a3.85 3.85 0 0 1-1.22-.68c-.35-.3-.6-.63-.77-1.01a.5.5 0 0 1 0-.48.63.63 0 0 1 .44-.28c.15-.03.29 0 .43.08a.7.7 0 0 1 .32.34c.1.2.26.39.48.56a3.14 3.14 0 0 0 2.89.39c.33-.12.6-.3.8-.53.2-.23.3-.52.3-.86 0-.43-.16-.82-.5-1.16-.34-.34-.9-.55-1.66-.63-1.01-.1-1.8-.4-2.38-.89-.57-.5-.86-1.1-.86-1.84 0-.54.15-1 .45-1.36.3-.38.7-.66 1.22-.86A4.9 4.9 0 0 1 5 7.21c.37.14.7.33.97.57.28.24.52.53.72.86.11.18.16.35.14.52a.48.48 0 0 1-.24.36.57.57 0 0 1-.5.06.69.69 0 0 1-.4-.31 2.02 2.02 0 0 0-1.13-.97c-.25-.1-.55-.15-.9-.15-.6-.01-1.1.1-1.5.34-.4.22-.61.58-.61 1.06 0 .24.06.48.18.7.13.22.36.41.7.58.34.16.83.27 1.46.34.97.1 1.72.4 2.25.89.55.48.82 1.13.82 1.93 0 .47-.1.87-.3 1.21a2.6 2.6 0 0 1-.76.86c-.32.23-.68.4-1.1.52-.4.11-.83.17-1.26.17Z"></path>
    </svg>
}

export default SbtIcon
