import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useDropzone} from 'react-dropzone'
import {Trans, useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {MAX_UPLOAD_SIZE} from '../../utils/constants'
import {ButtonElement, InputElement} from '../elements'
import {createIpfsLink} from '../../utils/functions'
import {
    getExternalUrl,
    getImageUrl,
    getLoadedImage,
    loadExternalUrl,
    resetImage, setExternalUrl,
    setImageUrlError, setLoadedImage
} from '../../store/inputSlice'

const SelectImageBlock = () => {
    const {t} = useTranslation()
    const externalUrl = useSelector(getExternalUrl)
    const imageUrl = useSelector(getImageUrl)
    const loadedImage = useSelector(getLoadedImage)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(resetImage())
        }
    }, [])
    useEffect(() => {
        if (externalUrl !== '') {
            dispatch(loadExternalUrl(externalUrl))
        }
    }, [externalUrl])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/*': [],
            'audio/*': ['.mpeg', '.ogg', '.wav', '.webm'],
            'video/*': ['.webm', '.mp4', '.ogg'],
            'model/gltf-binary': [],
            'model/gltf+json': [],
        },
        maxSize: MAX_UPLOAD_SIZE,
        maxFiles: 1,
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                dispatch(setImageUrlError(null))
                dispatch(setLoadedImage(acceptedFiles[0]))
            }
        },
    })

    const showNftImgPreview = () => {
        if (!loadedImage || !imageUrl.value) {
            return
        }

        const previewUrl = createIpfsLink(imageUrl.value)
        const type = loadedImage.type
        if (type.includes('video/')) {
            return <video
                className="img"
                style={{width: "100%", height: "100%"}}
                loop={true}
                autoPlay={true}
                muted={true}
            >
                <source src={previewUrl} type={type}/>
            </video>
        } else if (type.includes('audio/')) {
            return <audio controls={true} preload="none">
                <source src={previewUrl} type={type}/>
            </audio>
        } else {
            return <img
                src={previewUrl}
                className="img"
                alt=""
            />
        }
    }
    const onChangeHandler = (url: string) => {
        if (url !== '' && !url.match(/http/i)) {
            return
        }
        dispatch(setImageUrlError(null))
        dispatch(setExternalUrl(url))
    }

    return <div className="row mb-4">
        <div className="col-lg-4 col-xl-3 mb-4">
            {imageUrl.value ?
                <div className="upload-img-preview">
                    {showNftImgPreview()}
                    <button
                        className="btn btn-danger p-1"
                        onClick={() => {
                            dispatch(setLoadedImage(null))
                        }}
                    >
                        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M5.3 5.3a1 1 0 0 1 1.4 0l5.3 5.29 5.3-5.3a1 1 0 1 1 1.4 1.42L13.42 12l5.3 5.3a1 1 0 0 1-1.42 1.4L12 13.42l-5.3 5.3a1 1 0 0 1-1.4-1.42L10.58 12l-5.3-5.3a1 1 0 0 1 0-1.4Z"
                                  fill="#FFFFFF"></path>
                        </svg>
                    </button>
                </div>
                :
                <div
                    {...getRootProps({className: `upload-container dropzone ${isDragActive ? 'file-dragged' : ''} ${imageUrl.error.text !== '' ? 'is-invalid' : ''}`})}
                    role="presentation"
                    tabIndex={0}
                >
                    <div className="upload-poopover">
                        <div className="inner">
                            <Trans
                                i18nKey={'element.dropFile'}
                                components={[
                                    <b/>,
                                    <ButtonElement small outline additionalClass={'mt-3'} children={''}/>
                                ]}
                            />
                            <input {...getInputProps()}/>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className="col-lg-6">
            <div className="mb-4">
                <InputElement
                    value={externalUrl}
                    onChange={onChangeHandler}
                    label={t('form.label.enterUrl')}
                    errorText={imageUrl.error.text}
                />
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <p className="text-muted">{t('element.fileTypes')}: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
                        OGG, GLB, GLTF.</p>
                    <p className="text-muted">{t('element.maxSize', {size: MAX_UPLOAD_SIZE >> 20})}</p>
                    <p className="text-muted">
                        <a href={'https://pixabay.com'} target={'_blank'} rel={'noreferrer'}>Pixabay</a>,
                        {' '}
                        <a href={'https://unsplash.com'} target={'_blank'} rel={'noreferrer'}>Unsplash</a>,
                        {' '}
                        <a href={'https://www.pexels.com'} target={'_blank'} rel={'noreferrer'}>Pexels</a>,
                        {' '}
                        <a href={'https://www.freepik.com'} target={'_blank'} rel={'noreferrer'}>Freepik</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
}

export default SelectImageBlock
