import React from 'react'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {setModalVideo} from '../../store/appSlice'
import {AppDispatch} from '../../store/store'
import {ButtonElement} from './index'
import {PlayIcon} from '../icons'

interface propsType {
    link: string
    title: string
}

const VideoButton = (props: propsType) => {
    const {t} = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    const popupHandler = () => {
        dispatch(setModalVideo({
            link: props.link,
            title: props.title,
        }))
    }

    return <ButtonElement link additionalClass={'mt-n2 mt-xl-n1'} onClick={popupHandler}>
        <span className="me-2"><PlayIcon/></span>
        <span className="d-md-none">{t('word.tutorial')}</span>
        <span className="d-none d-md-inline">{t('word.videoTutorial')}</span>
    </ButtonElement>
}

export default VideoButton
