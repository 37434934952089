import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConnectWallet} from '@web3-onboard/react'
import {Trans, useTranslation} from 'react-i18next'
import {MintBlock} from './index'
import {mintNft, resetState, setMintContract} from '../../store/mintSlice'
import {CHAINS} from '../../utils/constants'
import {AppDispatch} from '../../store/store'
import {closeAllModals, getCurrentNetwork, getWalletAddress, getWeb3, setModalError} from '../../store/appSlice'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getSelectedOrganizerName} from '../../store/organizersSlice'
import {getSelectedTicketLevelName, setTicketLevelId} from '../../store/ticketsSlice'
import {VideoButton} from '../elements'
import {
    getBatch,
    getDescription, getImageUrl, getLoadedImage,
    getProperties,
    getTitle,
    setBatchError, setDescriptionError, setImageUrlError,
    setPropertiesError, setTitleError
} from '../../store/inputSlice'

const MintPage = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const eventName = useSelector(getSelectedEventName)
    const imageUrl = useSelector(getImageUrl)
    const loadedImage = useSelector(getLoadedImage)
    const organizerName = useSelector(getSelectedOrganizerName)
    const properties = useSelector(getProperties)
    const ticketBatch = useSelector(getBatch)
    const ticketDescription = useSelector(getDescription)
    const ticketLevelName = useSelector(getSelectedTicketLevelName)
    const ticketName = useSelector(getTitle)
    const walletAddress = useSelector(getWalletAddress)
    const web3 = useSelector(getWeb3)

    const [{wallet}] = useConnectWallet()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return () => {
            dispatch(closeAllModals())
            dispatch(resetState())
            dispatch(setTicketLevelId(null))
        }
    }, [walletAddress])
    useEffect(() => {
        if (web3 && currentNetwork) {
            dispatch(setMintContract(new web3.eth.Contract(CHAINS[currentNetwork].nftMinterContract721Abi, CHAINS[currentNetwork].nftMinterContract721)))
        } else {
            dispatch(setMintContract(null))
        }
    }, [currentNetwork])

    const submitHandler = () => {
        let error = false
        if (imageUrl.value === null) {
            error = true
            dispatch(setImageUrlError(t('error.chooseImage')))
        } else {
            dispatch(setImageUrlError(null))
        }

        if (organizerName === '' || eventName === '' || ticketLevelName === '') {
            error = true
        }

        if (!ticketBatch.value) {
            error = true
            dispatch(setBatchError(t('error.mustEnter', {name: t('form.label.batchAmount')})))
        } else if (ticketBatch.value > 100) {
            error = true
            dispatch(setBatchError(t('error.mustBeLessOrEqual', {name: t('form.label.batchAmount'), value: 100})))
        } else {
            dispatch(setBatchError(null))
        }

        if (ticketDescription.value === '') {
            error = true
            dispatch(setDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        } else if (ticketDescription.value.length < 3) {
            error = true
            dispatch(setDescriptionError(t('error.mustBeGreater', {name: t('form.label.description'), value: 2})))
        } else {
            dispatch(setDescriptionError(null))
        }

        if (ticketName.value === '') {
            error = true
            dispatch(setTitleError(t('error.mustEnter', {name: t('form.label.ticketName')})))
        } else {
            dispatch(setTitleError(null))
        }

        let haveError = false
        for (let item of properties.value) {
            if (item.trait_type === '' || item.value === '') {
                error = true
                dispatch(setPropertiesError(t('error.enterAllProperties')))
                haveError = true
                break
            }
        }
        if (!haveError) {
            dispatch(setPropertiesError(null))
        }

        if (!wallet) {
            dispatch(setModalError({text: t('error.walletNotConnected'), buttons: ['close']}))
            return
        }

        if (error) {
            return
        }

        if (loadedImage) {
            dispatch(mintNft())
        } else {
            dispatch(setModalError({text: t('error.imageLoadingError'), buttons: ['close']}))
        }
    }

    return <div className="page-content">
        <div className="container">
            <div className="row mb-3">
                <div className="col">
                    <h1>{t('header.mintTickets')}</h1>
                </div>
                <div className="col-auto">
                    <VideoButton
                        link={'https://www.youtube.com/embed/fkO68Cs__tI?si=z3gpDMidQQUifQBP'}
                        title={t('tutorial.title.mint')}
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-8">
                    <p>
                        <Trans i18nKey={'mint.mintPageDescription'} components={[<a href="https://youtu.be/fkO68Cs__tI?si=G2I8O-lq-Km0e1KA" target="_blank"/>]}/>
                    </p>
                </div>
            </div>
            <MintBlock maxBatchAmount={100} onSubmit={submitHandler}/>
        </div>
    </div>
}

export default MintPage
