import React, {ReactNode, useContext, useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import lodash from 'lodash'
import {Trans, useTranslation} from 'react-i18next'
import {getTickets, requestTokens, resetTickets, setTickets} from '../../store/ticketsSlice'
import {AppDispatch} from '../../store/store'
import {WalletNotConnected} from '../static'
import {
    AlertElement,
    ButtonElement,
    CheckboxElement, DateFilter,
    DropdownSelector,
    InputElement,
    NoteElement,
    SelectAllRow, WithTipElement
} from '../elements'
import {
    getCurrentNetwork,
    getTokenSmartcontracts,
    getWalletAddress,
    setModalAddTicketsToShowcaseV2, setModalCreateShowcaseV2, setModalVideo
} from '../../store/appSlice'
import {SelectShowcaseV2} from './index'
import {getEventsObject} from '../../store/eventsSlice'
import {requestCoins} from '../../store/coinsSlice'
import {FiltersIcon, LoadingIcon, PlayIcon} from '../icons'
import {getCollections, requestMintCollections} from '../../store/mintSlice'
import {ShowcaseV2TicketRow} from '../tickets'
import {
    getSelectedTickets, getShowcaseName,
    getShowcasesByType,
    setSelectedTickets,
    setShowcaseName,
    setShowcases
} from '../../store/showcaseV2Slice'
import {SHOWCASE_TOKENS_ON_PAGE} from '../../utils/constants'
import {ThemeContext} from '../../context'
import {ICheckedTicket, IDropdownItem, ITicket} from '../../store/types'
import {getTokens} from '../../store/tokensSlice'
import {getFromStorage, setToStorage} from '../../store/storage'
import {getDateFilter, getDateFilterAsc, setDateFilter} from '../../store/inputSlice'

const EditShowcasesV2 = () => {
    const {t, i18n} = useTranslation()
    const {showcaseType} = useContext(ThemeContext)
    const [addressFilter, setAddressFilter] = useState('')
    const [checkedSmartcontracts, setCheckedSmartcontracts] = useState(true)
    const [eventsFilter, setEventsFilter] = useState<IDropdownItem[]>([])
    const [filteredTickets, setFilteredTickets] = useState<ITicket[]>([])
    const [hideShowcaseNote, setHideShowcaseNote] = useState(getFromStorage('hideShowcaseNote'))
    const [levelsFilter, setLevelsFilter] = useState<IDropdownItem[]>([])
    const [nextPage, setNextPage] = useState(1)
    const [selectAll, setSelectAll] = useState(false)
    const [selectAllSilentChange, setSelectAllSilentChange] = useState(false)
    const [selectedEventFilter, setSelectedEventFilter] = useState(0)
    const [selectedLevelFilter, setSelectedLevelFilter] = useState('')
    const [showFilters, setShowFilters] = useState(false)
    const collections = useSelector(getCollections)
    const currentNetwork = useSelector(getCurrentNetwork)
    const dateFilter = useSelector(getDateFilter)
    const dateFilterAsc = useSelector(getDateFilterAsc)
    const events = useSelector(getEventsObject)
    const selectedTickets = useSelector(getSelectedTickets)
    const showcaseName = useSelector(getShowcaseName)
    const showcases = useSelector(getShowcasesByType(showcaseType), shallowEqual)
    const tickets = useSelector(getTickets)
    const tokens = useSelector(getTokens)
    const tokenSmartcontracts = useSelector(getTokenSmartcontracts, shallowEqual)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(requestTokens())
        }, 300000)
        return () => {
            clearInterval(timer)
            dispatch(resetTickets())
            dispatch(setShowcases(null))
            dispatch(setShowcaseName(null))
            dispatch(setSelectedTickets([]))
            dispatch(setDateFilter(null))
        }
    }, [])
    useEffect(() => {
        if (!currentNetwork) {
            return
        }

        if (tickets) {
            dispatch(setTickets(null))
        } else if (walletAddress) {
            dispatch(requestTokens())
        }
        if (walletAddress) {
            dispatch(requestMintCollections())
        }
    }, [walletAddress, currentNetwork])
    useEffect(() => {
        dispatch(requestCoins())
    }, [currentNetwork])
    useEffect(() => {
        dispatch(setSelectedTickets([]))
        if (!tickets && walletAddress && currentNetwork) {
            dispatch(requestTokens())
        }
        let levelsList: IDropdownItem[] = [{id: '', name: t('form.label.allLevels')}]
        let ids: string[] = []
        for (let item of tickets || []) {
            if (item.level && ids.indexOf(item.level.title) < 0) {
                levelsList.push({id: item.level.title, name: item.level.title})
                ids.push(item.level.title)
            }
        }
        setLevelsFilter(levelsList)
    }, [tickets, i18n.language])
    useEffect(() => {
        let eventsList: IDropdownItem[] = [{id: 0, name: t('form.label.allEvents')}]
        let ids: number[] = []
        for (let item of tickets || []) {
            if (item.eventId && events && events[item.eventId] && ids.indexOf(item.eventId) < 0) {
                eventsList.push({id: item.eventId, name: events[item.eventId].title})
                ids.push(item.eventId)
            }
        }
        setEventsFilter(eventsList)
    }, [tickets, events, i18n.language])
    useEffect(() => {
        if (!tickets) {
            return
        }

        let filtered = [...tickets].sort((itemA, itemB) => {
            return (itemA.blockNum - itemB.blockNum) * (dateFilterAsc ? 1 : -1)
        })
        filtered = filtered.filter((item) => {
            return item.tokenId.toString().indexOf(addressFilter) >= 0 || item.contract.indexOf(addressFilter.toLowerCase()) >= 0
        }).filter((item) => {
            return selectedEventFilter === 0 || item.eventId === selectedEventFilter
        }).filter((item) => {
            return selectedLevelFilter === '' || (item.level && item.level.title === selectedLevelFilter)
        }).filter((item) => {
            return !checkedSmartcontracts || tokenSmartcontracts.indexOf(item.contract) >= 0
        }).filter((item) => {
            if (!dateFilter) {
                return true
            }

            const date = tokens?.[`${item.contract}-${item.tokenId}`]?.date
            if (!date) {
                return false
            }

            const delta = date.getTime() - dateFilter.getTime()
            return delta >= 0 && delta < 86400000
        })
        let newSelectedTickets: ICheckedTicket[] = []
        let cnt = 0
        for (let filter of filteredTickets) {
            cnt++
            if (cnt > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
                break
            }

            if (selectAll) {
                newSelectedTickets.push({
                    contract: filter.contract,
                    tokenId: filter.tokenId,
                    assetType: filter.assetType
                })
                continue
            }

            for (let ticket of selectedTickets) {
                if (ticket.contract === filter.contract && ticket.tokenId === filter.tokenId) {
                    newSelectedTickets.push(ticket)
                    break
                }
            }
        }
        if (!lodash.isEqual(selectedTickets, newSelectedTickets)) {
            dispatch(setSelectedTickets(newSelectedTickets))
        }
        setFilteredTickets(filtered)
    }, [tickets, addressFilter, checkedSmartcontracts, dateFilter, selectAll, selectedEventFilter, selectedLevelFilter, tokenSmartcontracts])
    useEffect(() => {
        if (selectAllSilentChange) {
            setSelectAllSilentChange(false)
            return
        }

        if (!selectAll && selectedTickets.length > 0) {
            dispatch(setSelectedTickets([]))
        }
    }, [selectAll])
    useEffect(() => {
        const maxOnPage = SHOWCASE_TOKENS_ON_PAGE * nextPage < filteredTickets.length ? SHOWCASE_TOKENS_ON_PAGE * nextPage : filteredTickets.length
        if (selectedTickets.length > 0 && selectedTickets.length === maxOnPage) {
            if (!selectAll) {
                setSelectAll(true)
            }
        } else if (selectAll) {
            setSelectAllSilentChange(true)
            setSelectAll(false)
        }
    }, [selectedTickets, filteredTickets, nextPage])

    const addDisplayHandler = () => {
        if (!showcaseType) {
            return
        }

        dispatch(setModalCreateShowcaseV2({type: showcaseType}))
    }

    const ticketsList = useMemo(() => {
        if (!tickets) {
            return <LoadingIcon/>
        }

        if (showcases && showcases.length === 0) {
            return <div>
                <Trans i18nKey={'status.haveNotShowcases'} components={[<a href={'#'} onClick={addDisplayHandler}/>]}/>
            </div>
        }

        if (tickets.length === 0) {
            return <AlertElement type={'secondary'}>
                <Trans i18nKey={'status.haveNoTicketsInShowcase'} components={[<Link to={`/${showcaseType}/mint`}/>]}/>
            </AlertElement>
        }

        if (filteredTickets.length === 0) {
            return <div>{t('status.noNftFitsFilter')}</div>
        } else {
            const maxOnPage = SHOWCASE_TOKENS_ON_PAGE * nextPage < filteredTickets.length ? SHOWCASE_TOKENS_ON_PAGE * nextPage : filteredTickets.length
            let list: ReactNode[] = [<SelectAllRow
                key={0}
                checked={selectAll}
                onChange={setSelectAll}
                count={maxOnPage}
                eventTitle={events[selectedEventFilter]?.title || ''}
            />]
            list.push([...filteredTickets.slice(0, SHOWCASE_TOKENS_ON_PAGE * nextPage).map((item) => (<ShowcaseV2TicketRow key={`${item.contract}-${item.tokenId}`} item={item}/>))])
            if (filteredTickets.length > SHOWCASE_TOKENS_ON_PAGE * nextPage) {
                list.push(<ButtonElement
                    key={'loadMore'}
                    outline
                    onClick={() => {
                        setNextPage(nextPage + 1)
                    }}
                >{t('button.loadMore')}</ButtonElement>)
            }
            return list
        }
    }, [filteredTickets, nextPage, selectAll, selectedTickets, showcases, tickets])

    if (!walletAddress) {
        return <WalletNotConnected/>
    }

    if (!showcaseType) {
        return null
    }

    const addressFilterHandler = (value: string) => {
        if (value === '' || /^[a-zA-Z0-9]+$/.test(value)) {
            setAddressFilter(value)
        }
    }
    const addPriceHandler = () => {
        dispatch(setModalAddTicketsToShowcaseV2(true))
    }
    const hideHandler = () => {
        setHideShowcaseNote('hide')
        setToStorage('hideShowcaseNote', 'hide')
    }
    const tutorialHandler = () => {
        dispatch(setModalVideo({
            link: 'https://www.youtube.com/embed/LUHYvg97ezM?si=njd9WzFpleHAGYAv',
            title: t('tutorial.title.showcase')
        }))
    }

    return <div className="page-content">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="text-primary mb-1">{t(`menu.${showcaseType}`)}</div>
                    <h1>
                        <WithTipElement
                            tip={t('showcase.headerTip')}
                            children={t(`header.${showcaseType}ShowcaseManagement`)}
                        />
                    </h1>
                </div>
                <div className="col-auto">
                    <ButtonElement
                        link
                        additionalClass={'mt-n2 mt-xl-n1'}
                        onClick={tutorialHandler}
                    >
                        <span className="me-2"><PlayIcon/></span>
                        <span className="d-md-none">{t('word.tutorial')}</span>
                        <span className="d-none d-md-inline">{t('word.videoTutorial')}</span>
                    </ButtonElement>
                </div>
            </div>
            <div className="mb-5">
                <SelectShowcaseV2/>
                {!hideShowcaseNote && showcaseName ?
                    <NoteElement crossAction={hideHandler}>
                        <Trans
                            i18nKey={t('showcase.noteDescription')}
                            components={[<Link to={`/showcases/${Number(currentNetwork)}/${showcaseName}`}/>]}
                        />
                    </NoteElement>
                    :
                    null
                }
            </div>
            {showcases && showcases.length > 0 ?
                <>
                    <div className="row gx-3 gx-xl-4 mb-2 mb-xl-2">
                        <div
                            className="col-12 col-md-4 col-lg-auto mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DateFilter/>
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={eventsFilter}
                                setItem={setSelectedEventFilter}
                                currentItem={selectedEventFilter}
                                button
                            />
                        </div>
                        <div
                            className="col-12 col-md-4 col-lg-2 mb-3 order-1 order-md-0 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <DropdownSelector
                                list={levelsFilter}
                                setItem={setSelectedLevelFilter}
                                currentItem={selectedLevelFilter}
                                button
                            />
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 col-xl-2 mb-4 order-0">
                            <InputElement
                                value={addressFilter}
                                onChange={addressFilterHandler}
                                additionalClass={'form-control form-control-sm control-search'}
                                placeholder={t('form.label.addressOrId')}
                            />
                        </div>
                        <div className="col-4 mb-4 order-0 d-md-none">
                            <ButtonElement
                                small
                                outline
                                additionalClass={`btn-filter-toggle w-100 ${showFilters ? 'show' : ''}`}
                                onClick={() => {
                                    setShowFilters(!showFilters)
                                }}
                            >
                                <FiltersIcon className="me-2"/>
                                <span>{t('button.filters')}</span>
                            </ButtonElement>
                        </div>
                        <div
                            className="col-md-8 col-lg-auto order-1 mb-4 col-filter__mob-toggled"
                            style={showFilters ? {display: 'block'} : {}}
                        >
                            <CheckboxElement
                                checked={checkedSmartcontracts}
                                onChange={() => {
                                    setCheckedSmartcontracts(!checkedSmartcontracts)
                                }}
                                label={t('form.label.myshchSmartContracts')}
                                loading={collections === null}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="row-table">{ticketsList}</div>
                    </div>
                    {selectedTickets.length > 0 ?
                        <div className="showcase-nav-bottom">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-sm-auto my-2">
                                        {t('showcase.selectedTickets', {count: selectedTickets.length})}
                                    </div>
                                    <div className="col-12 col-sm-auto my-2">
                                        <ButtonElement
                                            additionalClass={'w-100'}
                                            onClick={addPriceHandler}
                                        >{t('button.addPrice')}</ButtonElement>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </>
                :
                <div>
                    <Trans i18nKey={'status.haveNotShowcases'} components={[<a href={'#'} onClick={addDisplayHandler}/>]}/>
                </div>
            }
        </div>
    </div>
}

export default EditShowcasesV2
