import React from 'react'
import {Trans} from 'react-i18next'
import {CheckboxElement} from './index'

interface IProps {
    checked: boolean
    count: number
    eventTitle: string
    onChange: (val: boolean) => void
}

const SelectAllRow = (props: IProps) => {
    const onChangeHandler = () => {
        props.onChange(!props.checked)
    }

    return <div className="row-table__item mb-4 mb-xl-2">
        <div className="row align-items-center gx-2 gx-md-4">
            <div className="mb-2 col">
                <CheckboxElement
                    checked={props.checked}
                    onChange={onChangeHandler}
                    label={<>
                        <Trans i18nKey={'form.selectAllItems'} components={[<strong/>]} count={props.count}/>
                        {' '}
                        {props.eventTitle !== '' ?
                            <span className="d-xl-none">
                                <Trans
                                    i18nKey={'showcase.selectAllEventTitle'}
                                    components={[<b className="text-primary"/>]}
                                    values={{name: props.eventTitle}}
                                />
                            </span>
                            :
                            null
                        }
                    </>}
                />
            </div>
        </div>
    </div>
}

export default SelectAllRow
