import React from 'react'
import Tippy from '@tippyjs/react'
import {TipIcon} from '../icons'

interface propsType {
    children: string
    tip: string
}

const WithTipElement = (props: propsType) => {
    const strArray = props.children.trim().split(' ')
    const firstPart = strArray.slice(0, strArray.length - 1).join(' ')
    const lastWord = strArray[strArray.length - 1]

    return <>{firstPart} <span className="d-inline-block">{lastWord}&nbsp;
        <Tippy
            content={props.tip}
            appendTo={document.getElementsByClassName("wrapper")[0]}
            trigger='mouseenter'
            interactive={false}
            arrow={false}
            maxWidth={512}
        >
            <span><TipIcon className={'icon-primary blockquote'}/></span>
        </Tippy>
    </span></>
}

export default WithTipElement
