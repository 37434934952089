import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Trans, useTranslation} from 'react-i18next'
import {ethers} from 'ethers'
import {useConnectWallet} from '@web3-onboard/react'
import {AlertElement, ButtonElement, VideoButton} from '../elements'
import {ThemeContext} from '../../context'
import {SelectGiftsCollection} from './index'
import {CollateralBlock, PropertiesBlock, RecipientsBlock, SelectImageBlock, TitleAndDescriptionBlock} from '../blocks'
import {AppDispatch} from '../../store/store'
import {
    getSelectedCollection,
    getSelectedCollectionError,
    mintGifts,
    setSelectedCollectionError
} from '../../store/giftsSlice'
import {
    getCollaterals,
    getDescription, getImageUrl,
    getRecipients,
    getTitle, setCollateralsError,
    setDescriptionError, setExternalUrl, setImageUrlError, setRecipientsError,
    setTitleError
} from '../../store/inputSlice'
import {checkWallet, getCurrentNetwork, sendRequestWithAuth, setModalGenerateAIGift} from '../../store/appSlice'
import {getBalances} from '../../store/userSlice'
import {APP_URL, CHAINS, CUSTOM_DROPDOWN_ITEM, NULL_ADDRESS} from '../../utils/constants'
import {getPublicCollectionDropdown} from '../../utils/functions'

const Gifts = () => {
    const {t} = useTranslation()
    const {dark} = useContext(ThemeContext)
    const [activeTab, setActiveTab] = useState<number>(1)
    const balances = useSelector(getBalances)
    const collaterals = useSelector(getCollaterals)
    const currentNetwork = useSelector(getCurrentNetwork)
    const description = useSelector(getDescription)
    const imageUrl = useSelector(getImageUrl)
    const recipients = useSelector(getRecipients)
    const selectedCollection = useSelector(getSelectedCollection)
    const selectedCollectionError = useSelector(getSelectedCollectionError)
    const title = useSelector(getTitle)
    const publicDropdown = getPublicCollectionDropdown()

    const dispatch = useDispatch<AppDispatch>()
    const [{wallet}] = useConnectWallet()

    useEffect(() => {
        checkBalance()
    }, [collaterals, recipients])

    const checkBalance = (): boolean => {
        const batch = recipients.value.length
        for (let item of collaterals.value) {
            const token = item.token === CUSTOM_DROPDOWN_ITEM.id ? item.customContract : item.token
            let balance: string | undefined = balances[token]?.balance
            if (token === NULL_ADDRESS && wallet && currentNetwork) {
                balance = wallet.accounts[0]?.balance?.[CHAINS[currentNetwork].token]
            }
            if (balance === undefined ||
                ethers.utils.parseEther(balance).lt(ethers.utils.parseEther(item.price).mul(batch || 1))
            ) {
                if (!collaterals.error.status) {
                    dispatch(setCollateralsError(t('error.insufficientBalance')))
                }
                return false
            }
        }
        if (collaterals.error.status) {
            dispatch(setCollateralsError(null))
        }
        return true
    }
    const checkButtonDisabled = (): boolean => {
        if (!selectedCollection && !selectedCollectionError) {
            dispatch(setSelectedCollectionError(t('error.collectionNotSelected')))
        }
        return recipients.error.status || collaterals.error.status || imageUrl.error.status || title.error.status ||
            description.error.status || !selectedCollection
    }
    const createGifts = () => {
        let error = false
        if (!selectedCollection) {
            error = true
            dispatch(setSelectedCollectionError(t('error.collectionNotSelected')))
        }
        const batch = recipients.value.length
        if (!batch) {
            error = true
            dispatch(setRecipientsError(t('error.mustBeGreater', {name: t('form.label.recipients'), value: 0})))
        } else {
            if (!checkBalance()) {
                error = true
            }
        }
        if (imageUrl.value === null) {
            error = true
            dispatch(setImageUrlError(t('error.chooseImage')))
        }
        if (title.value === '') {
            error = true
            dispatch(setTitleError(t('error.mustEnter', {name: t('form.label.giftName')})))
        } else if (title.error.status) {
            error = true
        }
        if (description.value === '') {
            error = true
            dispatch(setDescriptionError(t('error.mustEnter', {name: t('form.label.description')})))
        } else if (description.error.status) {
            error = true
        }
        if (error) {
            return
        }

        dispatch(checkWallet({request: sendRequestWithAuth(mintGifts())}))
    }
    const setAIActiveTab = () => {
        setActiveTab(1)
        dispatch(setModalGenerateAIGift({template: false}))
    }
    const setTemplateActiveTab = () => {
        setActiveTab(2)
        //dispatch(setModalGenerateAIGift({template: true}))
        dispatch(setImageUrlError(null))
        dispatch(setExternalUrl(`${APP_URL}/img/templateTab.png`))
    }

    return <div className="page-content page--mint-gifts">
        <img className="page-bg" src={`/img/bg/${dark ? 'dark' : 'light'}-mode/bg-gifts.svg`} alt=""/>
        <div className="container">
            <div className="row mb-2">
                <div className="col">
                    <h1>{t('section.gifts')}</h1>
                </div>
                <div className="col-auto">
                    <VideoButton
                        link={'https://www.youtube.com/embed/HPcWJZa0wEI?si=s-VzunKIHmsa67QQ'}
                        title={'How to give an on-chain gift?'}
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-lg-8">
                    <p>{t('gifts.pageDescription')}</p>
                    <p>
                        <a
                            href={'https://docs.myshch.io/myshch/tutorials/for-event-organisers/gifts-for-visitors'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >{t('action.readMore')}</a>
                    </p>
                </div>
            </div>
            <SelectGiftsCollection/>
            <div className="mb-6 pb-4">
                <h2 className="mb-6">{t('header.imageForGift')}</h2>
                <div className="row mb-4">
                    <div className="mb-2 col-md-6 col-lg-4 col-xl-3">
                        <button
                            className={`btn btn__img-source ${activeTab === 0 ? 'active' : ''}`}
                            onClick={() => setActiveTab(0)}
                        >
                            <span className="desc">
                                <span className="title">{t('gifts.imageTab.ownFile')}</span>
                                <small className="text-muted">{t('gifts.imageTab.ownFileDescription')}</small>
                            </span>
                        </button>
                    </div>
                    <div className="mb-2 col-md-6 col-lg-4 col-xl-3">
                        <button
                            className={`btn btn__img-source ${activeTab === 1 ? 'active' : ''}`}
                            onClick={setAIActiveTab}
                        >
                            <span className="desc">
                                <span className="title">{t('gifts.imageTab.aiGenerated')}</span>
                                <small className="text-muted">{t('gifts.imageTab.aiGeneratedDescription')}</small>
                            </span>
                        </button>
                    </div>
                    <div className="mb-2 col-md-6 col-lg-4 col-xl-3">
                        <button
                            className={`btn btn__img-source ${activeTab === 2 ? 'active' : ''}`}
                            onClick={setTemplateActiveTab}
                        >
                            <span className="desc">
                                <span className="title">{t('gifts.imageTab.dailyTemplate')}</span>
                                <small className="text-muted">{t('gifts.imageTab.dailyTemplateDescription')}</small>
                            </span>
                            <span className="img"><img src="../img/templateTab.png" alt=""/></span>
                        </button>
                    </div>
                </div>
                <SelectImageBlock/>
                <TitleAndDescriptionBlock
                    descriptionLabel={t('form.label.description')}
                    titleLabel={t('form.label.giftName')}
                />
                {selectedCollection !== publicDropdown.id ? <PropertiesBlock/> : null}
                <RecipientsBlock/>
                {selectedCollection !== publicDropdown.id ? <CollateralBlock/> : null}
                {checkButtonDisabled() ?
                    <div className="row">
                        <div className="col-lg-8">
                            <AlertElement type={'danger'}>
                                <Trans i18nKey={'error.fillAllFields'} components={[<span className="text-danger"/>]}/>
                            </AlertElement>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="row mb-6">
                    <div className="col-lg-3">
                        <ButtonElement
                            additionalClass={'w-100'}
                            onClick={createGifts}
                        >{t('button.createGifts')}</ButtonElement>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Gifts
