import React from 'react'
import {IconProps} from '../../store/types'

const CalendarIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipPath="evenodd"
              d="M5.91 1a1 1 0 0 1 1 1v.69h9.46V2a1 1 0 1 1 2 0v.8a4.38 4.38 0 0 1 3.38 4.26v11.25a4.38 4.38 0 0 1-4.38 4.38H6.13a4.38 4.38 0 0 1-4.37-4.38V7.06c0-2 1.34-3.68 3.16-4.2V2a1 1 0 0 1 1-1Zm.21 3.69a2.37 2.37 0 0 0-2.37 2.37v.42a1 1 0 0 1 .13-.01h15.75a1 1 0 0 1 .12 0v-.4a2.37 2.37 0 0 0-2.38-2.38H6.13Zm13.63 4.77a1 1 0 0 1-.13 0H3.88a1 1 0 0 1-.12 0v8.85a2.37 2.37 0 0 0 2.38 2.38h11.25a2.37 2.37 0 0 0 2.37-2.38V9.46Zm-7.44 2.42a1 1 0 0 1 1 1v.09a1 1 0 1 1-2 0v-.09a1 1 0 0 1 1-1Zm4.5 0a1 1 0 0 1 1 1v.09a1 1 0 1 1-2 0v-.09a1 1 0 0 1 1-1Zm-9.56 4.59a1 1 0 0 1 1 1v.08a1 1 0 1 1-2 0v-.08a1 1 0 0 1 1-1Zm5.06 0a1 1 0 0 1 1 1v.08a1 1 0 1 1-2 0v-.08a1 1 0 0 1 1-1Z"></path>
    </svg>
}

export default CalendarIcon
