import React from 'react'
import {IconProps} from '../../store/types'

const ArrowCurveIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.5999 3.2C3.04762 3.2 2.5999 3.64771 2.5999 4.2V10.486C2.5999 13.2474 4.83848 15.486 7.59991 15.486H17.9857L14.3788 19.0929C13.9883 19.4834 13.9883 20.1166 14.3788 20.5071C14.7693 20.8976 15.4025 20.8976 15.793 20.5071L21.107 15.1931C21.4975 14.8026 21.4975 14.1694 21.107 13.7789L15.793 8.46487C15.4025 8.07435 14.7693 8.07435 14.3788 8.46487C13.9883 8.85539 13.9883 9.48856 14.3788 9.87908L17.9857 13.486H7.59991C5.94305 13.486 4.5999 12.1428 4.5999 10.486V4.2C4.5999 3.64771 4.15219 3.2 3.5999 3.2Z"/>
    </svg>
}

export default ArrowCurveIcon
