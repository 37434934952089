import React, {ReactElement} from 'react'
import {useSelector} from 'react-redux'
import {getCurrentNetwork, getWalletAddress} from '../../store/appSlice'
import {WalletNotConnected, WrongNetwork} from '../static'
import {CHAINS} from '../../utils/constants'

interface PropsType {
    children: ReactElement
    networkList?: string[]
}

const CheckNetwork = ({children, networkList}: PropsType) => {
    const currentNetwork = useSelector(getCurrentNetwork)
    const walletAddress = useSelector(getWalletAddress)

    if (!walletAddress) {
        return <WalletNotConnected/>
    }

    const networks = networkList || Object.keys(CHAINS)
    if (currentNetwork && networks.indexOf(currentNetwork) >= 0) {
        return children
    } else {
        return <WrongNetwork networkList={networks}/>
    }
}

export default CheckNetwork
