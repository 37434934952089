import React from 'react'
import {IconProps} from '../../store/types'

const ChevronDownIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.7 15.1a1 1 0 0 1-1.4 0l-4.8-4.8a1 1 0 1 1 1.4-1.4l4.1 4.09 4.1-4.1a1 1 0 1 1 1.4 1.42l-4.8 4.8Z"/>
    </svg>
}

export default ChevronDownIcon
