import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {getCurrentNetwork, getWalletAddress, setModalCreateDisplay} from '../../store/appSlice'
import {
    getDisplay,
    getDisplayId,
    getDisplayName,
    getDisplays,
    setDisplayId,
    setDisplayName
} from '../../store/launchpadSlice'
import {ButtonElement, DropdownPlaceholder, DropdownSelector} from '../elements'
import {AddIcon, ArrowCurveIcon} from '../icons'
import {IDropdownItem} from '../../store/types'

const SelectDisplay = () => {
    const {t} = useTranslation()
    const currentNetwork = useSelector(getCurrentNetwork)
    const display = useSelector(getDisplay)
    const displayId = getDisplayId()
    const displayName = useSelector(getDisplayName)
    const displays = useSelector(getDisplays)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!displays) {
            return
        }

        if (displayId !== null) {
            if (display) {
                dispatch(setDisplayName(display.name))
            } else {
                setDisplayId(null)
                dispatch(setDisplayName(null))
            }
        } else if (displays.length > 0) {
            setDisplayId(displays[0].id)
            dispatch(setDisplayName(displays[0].name))
        } else {
            dispatch(setDisplayName(null))
        }
    }, [displays, displayName, walletAddress])

    const addDisplayHandler = () => {
        dispatch(setModalCreateDisplay(true))
    }
    const displayList: IDropdownItem[] = displays?.map((item): IDropdownItem => {
        return {id: item.id, name: item.name}
    }) || []

    return <div className="mb-5">
        <div className="row gx-3">
            <div className="col-8 col-md-4 col-xl-3">
                <div className="mb-3">
                    {displays ?
                        <DropdownSelector
                            list={displayList}
                            setItem={(id) => {
                                setDisplayId(id)
                                dispatch(setDisplayName(''))
                            }}
                            currentItem={displayId}
                        />
                        :
                        <DropdownPlaceholder title={`${t('status.loading')}...`}/>
                    }
                </div>
            </div>
            <div className="col-2 col-md-auto">
                {currentNetwork && displayName ?
                    <NavLink
                        to={`/showcase/${Number(currentNetwork)}/${displayName}`}
                        className={'btn btn-link w-100 px-2'}
                    >
                        <span className={'me-md-2'}><ArrowCurveIcon/></span>
                        <span className="d-none d-md-inline">{t('button.goToShowcase')}</span>
                    </NavLink>
                    :
                    null
                }
            </div>
            <div className="col-2 col-md-auto">
                <ButtonElement
                    link
                    additionalClass="w-100 px-2"
                    onClick={addDisplayHandler}
                >
                    <span className={'me-2'}><AddIcon/></span>
                    <span className="d-none d-md-inline">{t('button.addShowcase')}</span>
                </ButtonElement>
            </div>
        </div>
    </div>
}

export default SelectDisplay
