import React, {ReactNode} from 'react'
import {CrossIcon} from '../icons'

interface propsType {
    crossAction?: () => void
    children: ReactNode
}

const NoteElement = (props: propsType) => {
    return <div className="border rounded px-3 mb-2">
        <div className="d-flex justify-content-between align-items-start">
            <div className="py-3">{props.children}</div>
            {props.crossAction ?
                <div className="py-2">
                    <button className="btn p-1 btn-with-opacity" onClick={props.crossAction}>
                        <CrossIcon/>
                    </button>
                </div>
                :
                null
            }
        </div>
    </div>
}

export default NoteElement
