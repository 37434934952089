import React from 'react'
import {useSelector} from 'react-redux'
import {ChainSelector, ConnectWalletButton, UserMenu} from './index'
import {getUser} from '../../store/authSlice'
import {getWalletAddress} from '../../store/appSlice'

const WalletBlock = () => {
    const user = useSelector(getUser)
    const walletAddress = useSelector(getWalletAddress)

    return <>
        {walletAddress ?
            <>
                {user ?
                    <ChainSelector/>
                    :
                    null
                }
                <UserMenu/>
            </>
            :
            <ConnectWalletButton small/>
        }
    </>
}

export default WalletBlock
