import React from 'react'
import {IconProps} from '../../store/types'

const AddTicketIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.4 1a1 1 0 1 0-2 0v2.4H16a1 1 0 1 0 0 2h2.4v2.39a1 1 0 1 0 2 0v-2.4h2.39a1 1 0 1 0 0-2h-2.4V1ZM2.2 7.27a2.81 2.81 0 0 1 2.81-2.81h7v-2H5A4.81 4.81 0 0 0 .2 7.27v9.46a4.81 4.81 0 0 0 4.81 4.81h7a1 1 0 0 0 1-.97 1.63 1.63 0 0 1 3.24 0 1 1 0 0 0 1 .97h1.74a4.81 4.81 0 0 0 4.81-4.81V12a1 1 0 1 0-2 0v4.73a2.81 2.81 0 0 1-2.81 2.81h-.9a3.63 3.63 0 0 0-6.93 0H5.01a2.81 2.81 0 0 1-2.81-2.81V7.27Zm13.2 1.68a1 1 0 0 0-2 0v.2a1 1 0 1 0 2 0v-.2Zm0 3a1 1 0 0 0-2 0v.19a1 1 0 1 0 2 0v-.2Zm0 3a1 1 0 0 0-2 0v.19a1 1 0 1 0 2 0v-.2Z"></path>
    </svg>
}

export default AddTicketIcon
