import React from 'react'
import {IconProps} from '../../store/types'

const CertificateIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.2002 6.00002C3.2002 3.45951 5.25969 1.40002 7.8002 1.40002H16.2002C18.7407 1.40002 20.8002 3.45951 20.8002 6.00002V9.00002C20.8002 9.55231 20.3525 10 19.8002 10C19.2479 10 18.8002 9.55231 18.8002 9.00002V6.00002C18.8002 4.56408 17.6361 3.40002 16.2002 3.40002H7.8002C6.36425 3.40002 5.2002 4.56408 5.2002 6.00002V18C5.2002 19.436 6.36425 20.6 7.8002 20.6H10.2002C10.7525 20.6 11.2002 21.0477 11.2002 21.6C11.2002 22.1523 10.7525 22.6 10.2002 22.6H7.8002C5.25969 22.6 3.2002 20.5405 3.2002 18V6.00002ZM6.8002 7.20002C6.8002 6.64774 7.24791 6.20002 7.8002 6.20002H15.6002C16.1525 6.20002 16.6002 6.64774 16.6002 7.20002C16.6002 7.75231 16.1525 8.20002 15.6002 8.20002H7.8002C7.24791 8.20002 6.8002 7.75231 6.8002 7.20002ZM6.8002 10.8C6.8002 10.2477 7.24791 9.80002 7.8002 9.80002H12.0002C12.5525 9.80002 13.0002 10.2477 13.0002 10.8C13.0002 11.3523 12.5525 11.8 12.0002 11.8H7.8002C7.24791 11.8 6.8002 11.3523 6.8002 10.8ZM17.963 14.8994C17.231 14.5334 16.3694 14.5334 15.6374 14.8994L14.8002 15.3181V18.045C14.8002 18.104 14.8446 18.3464 15.2926 18.8073C15.6353 19.1597 16.1357 19.5594 16.8052 20.0101C17.4957 19.5619 17.9899 19.2079 18.3296 18.8793C18.7245 18.4972 18.8002 18.2562 18.8002 18.045V15.3181L17.963 14.8994ZM14.743 13.1106C16.038 12.4631 17.5624 12.4631 18.8574 13.1106L20.2474 13.8056C20.5862 13.975 20.8002 14.3212 20.8002 14.7V18.045C20.8002 18.9888 20.3421 19.7151 19.7201 20.3168C19.1254 20.892 18.2865 21.4441 17.3342 22.0455C17.008 22.2515 16.5924 22.2515 16.2662 22.0455C15.2747 21.4194 14.4479 20.8076 13.8585 20.2013C13.2896 19.616 12.8002 18.891 12.8002 18.045V14.7C12.8002 14.3212 13.0142 13.975 13.353 13.8056L14.743 13.1106ZM6.8002 14.4C6.8002 13.8477 7.24791 13.4 7.8002 13.4H10.2002C10.7525 13.4 11.2002 13.8477 11.2002 14.4C11.2002 14.9523 10.7525 15.4 10.2002 15.4H7.8002C7.24791 15.4 6.8002 14.9523 6.8002 14.4Z"/>
    </svg>
}

export default CertificateIcon
