import React from 'react'
import {Link} from 'react-router-dom'
import {DBEventWithOrganizer} from '../../store/types'
import {EventCard} from './index'

interface PropsType {
    event: DBEventWithOrganizer
    link: string
}

const EventTitleWithPreview = ({event, link}: PropsType) => {
    return <>
        <Link to={link} className={'d-block text-truncate'}>{event.title}</Link>
        <div className="item-event-preview">
            <EventCard event={event}/>
        </div>
    </>
}

export default EventTitleWithPreview
