import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getModalConfirmation, setModalConfirmation} from '../../store/appSlice'
import {AppDispatch} from '../../store/store'
import {ButtonElement} from '../elements'

interface propsType {
    show: boolean
}

const ModalConfirmation = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const modal = useSelector(getModalConfirmation)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalConfirmation(null))
    }

    if (!modal || !props.show) {
        return null
    }

    return <div
        className={`modal fade show`}
        ref={modalFade}
        id="confirmActionModal"
        tabIndex={-1}
        aria-labelledby="Error"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-2">
                    <h2 className="modal-title">{modal.title}</h2>
                </div>
                <div className="modal-body">
                    {modal.text ? <p>{modal.text}</p> : null}
                </div>
                <div className="modal-footer justify-content-between">
                    <ButtonElement
                        onClick={() => {
                            modal.confirmAction()
                            closeModal()
                        }}
                    >{t('button.yes')}</ButtonElement>
                    <ButtonElement
                        outline
                        type={'secondary'}
                        onClick={() => {
                            closeModal()
                        }}
                    >{t('button.cancel')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalConfirmation
