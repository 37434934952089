import React from 'react'
import {IconProps} from '../../store/types'

const ExternalLinkIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M19.1854 3.40006L15.5994 3.40024C15.0471 3.40027 14.5993 2.95257 14.5993 2.40029C14.5993 1.848 15.047 1.40027 15.5993 1.40024L21.5999 1.39995C21.8651 1.39994 22.1194 1.50529 22.307 1.69282C22.4945 1.88036 22.5999 2.13472 22.5999 2.39995V7.80009C22.5999 8.35238 22.1522 8.80009 21.5999 8.80009C21.0476 8.80009 20.5999 8.35238 20.5999 7.80009V4.81397L12.1061 13.3068C11.7155 13.6973 11.0824 13.6973 10.6919 13.3067C10.3014 12.9162 10.3014 12.283 10.692 11.8925L19.1854 3.40006ZM5.9999 3.39995C4.56396 3.39995 3.3999 4.56401 3.3999 5.99995V18.0001C3.3999 19.436 4.56396 20.6001 5.9999 20.6001H17.9999C19.4358 20.6001 20.5999 19.436 20.5999 18.0001V13.1999C20.5999 12.6477 21.0476 12.1999 21.5999 12.1999C22.1522 12.1999 22.5999 12.6477 22.5999 13.1999V18.0001C22.5999 20.5406 20.5404 22.6001 17.9999 22.6001H5.9999C3.45939 22.6001 1.3999 20.5406 1.3999 18.0001V5.99995C1.3999 3.45944 3.45939 1.39995 5.9999 1.39995H10.7999C11.3522 1.39995 11.7999 1.84766 11.7999 2.39995C11.7999 2.95223 11.3522 3.39995 10.7999 3.39995H5.9999Z"/>
    </svg>
}

export default ExternalLinkIcon
