import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {sendRequestWithAuth, setModalCreateTicketLevels} from '../../store/appSlice'
import {ButtonElement, HiddenTextBlock, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {getSelectedEventName} from '../../store/eventsSlice'
import {getTicketLevels, postTicketLevel, requestTicketLevels, setTicketLevelId} from '../../store/ticketsSlice'
import {getJwt} from '../../store/authSlice'
import {CloseIcon} from '../icons'

interface propsType {
    show: boolean
}

const ModalCreateTicketLevels = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [newName, setNewName] = useState('')
    const [newLevel, setNewLevel] = useState('0')
    const [nameError, setNameError] = useState('')
    const [levelError, setLevelError] = useState('')
    const eventName = useSelector(getSelectedEventName)
    const jwt = useSelector(getJwt)
    const ticketLevels = useSelector(getTicketLevels)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (ticketLevels === null && eventName !== '' && jwt) {
            dispatch(requestTicketLevels())
            setNewLevel('0')
            setNewName('')
        }
    }, [ticketLevels, jwt])


    const closeModal = () => {
        dispatch(setModalCreateTicketLevels(false))
    }
    const addTicketLevel = () => {
        let error = false
        const name = newName.trim()
        const level = parseInt(newLevel)
        if (name === '') {
            error = true
            setNameError(t('error.mustEnter', {name: t('form.label.name')}))
        } else {
            setNameError('')
        }
        if (level >= 0 && level <= 127) {
            setLevelError('')
        } else {
            error = true
            setLevelError(t('error.mustBeGreaterOrEqualAndLess', {name: t('form.label.level'), value: 0, value2: 128}))
        }
        if (error) {
            return
        }
        dispatch(sendRequestWithAuth(postTicketLevel({title: name, level})))
    }
    const select = (id: number) => {
        dispatch(setTicketLevelId(id))
        closeModal()
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Select ticket level"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header pb-3">
                    <h2 className="modal-title">{t('modal.title.selectTicketLevel')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <HiddenTextBlock
                        text={<>
                            <p>{t('modal.selectTicketLevelDescription1')}</p>
                            <ul className="ps-3">
                                <li className="mb-1">Normal - 0</li>
                                <li className="mb-1">Advanced - 1</li>
                                <li className="mb-1">VIP - 2</li>
                            </ul>
                            <p>{t('modal.selectTicketLevelDescription1')}</p>
                        </>}
                        title={t('modal.selectTicketLevelDescriptionTitle')}
                    />
                    <div className="row gx-3 mb-2">
                        <div className="col-lg-5">
                            <div className="mb-4">
                                <InputElement
                                    errorText={nameError}
                                    value={newName}
                                    onChange={setNewName}
                                    label={t('form.label.levelName')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="mb-4">
                                <InputElement
                                    errorText={levelError}
                                    value={newLevel}
                                    onChange={(value) => {
                                        let lvl = parseInt(value) || 0
                                        lvl = lvl < 0 ? 0 : (lvl > 127 ? 127 : lvl)
                                        setNewLevel(lvl.toString())
                                    }}
                                    label={t('form.label.levelNumber')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="mb-4">
                                <label className="form-label d-none d-lg-block">&nbsp;</label>
                                <ButtonElement
                                    outline
                                    additionalClass="w-100"
                                    onClick={addTicketLevel}
                                >{t('button.add')}</ButtonElement>
                            </div>
                        </div>
                    </div>
                    <div>
                        {ticketLevels ?
                            ticketLevels.map((data) => {
                                const {id, level, title} = data
                                return <div className="border-bottom" key={id}>
                                    <div className="row pt-4 pt-md-1 align-items-center">
                                        <div className="col-12 col-md-7 col-lg-5 pb-1">{title}</div>
                                        <div className="col-6 col-md pb-1">{level}</div>
                                        <div className="col-6 col-md-auto pb-1">
                                            <ButtonElement link additionalClass="w-100" onClick={() => {
                                                select(id)
                                            }}>{t('button.select')}</ButtonElement>
                                        </div>
                                    </div>
                                </div>
                            })
                            :
                            <div>{t('status.loading')}...</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateTicketLevels
