import React from 'react'
import {IconProps} from '../../store/types'

const SuccessIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 12A12 12 0 0 1 17.25 1.2a1 1 0 0 1-.88 1.8A10 10 0 1 0 22 12a1 1 0 1 1 2 0 12 12 0 0 1-24 0Zm21.64-7.58a1 1 0 0 1 0 1.41l-9.62 9.63a1 1 0 0 1-1.41 0L7.86 12.7a1 1 0 1 1 1.41-1.42l2.04 2.05 8.92-8.92a1 1 0 0 1 1.41 0Z"></path>
    </svg>
}

export default SuccessIcon
