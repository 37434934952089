import React from 'react'
import {IconProps} from '../../store/types'

const BackIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2ZM1.4 12a10.6 10.6 0 1 1 21.2 0 10.6 10.6 0 0 1-21.2 0Zm12.38-5.22a1 1 0 0 1 .12 1.4l-3 3.6 3 3.6a1 1 0 0 1-1.53 1.29l-3.54-4.24a1 1 0 0 1 0-1.28l3.54-4.25a1 1 0 0 1 1.4-.12Z"></path>
    </svg>
}

export default BackIcon
