import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {getEvent, requestEvent} from '../../store/eventsSlice'
import {AppDispatch} from '../../store/store'
import {CalendarIcon} from '../icons'
import {getEventStats, requestEventStats, setEventStats} from '../../store/statisticSlice'
import {sendRequestWithAuth} from '../../store/appSlice'

const EventStatistic = () => {
    const {t} = useTranslation()
    const params = useParams()
    const eventId = Number(params.eventId)
    const event = useSelector(getEvent(eventId))
    let startDate = ''
    let startTime = ''
    let endDate = ''
    let endTime = ''
    let eventDate = ''
    if (event) {
        let date = moment(event.startTime).format()
        startDate = date.substring(0, date.indexOf('T'))
        date = date.substring(date.indexOf('T') + 1)
        startTime = date.substring(0, 5)
        date = moment(event.endTime).format()
        endDate = date.substring(0, date.indexOf('T'))
        date = date.substring(date.indexOf('T') + 1)
        endTime = date.substring(0, 5)
        if (startDate === endDate) {
            eventDate = `${startDate} ${startTime} - ${endTime}`
        } else {
            eventDate = `${startDate} ${startTime} - ${endDate} ${endTime}`
        }
    }
    const stats = useSelector(getEventStats)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestEvent(eventId))
        dispatch(sendRequestWithAuth(requestEventStats(eventId)))
        return () => {
            dispatch(setEventStats(null))
        }
    }, [eventId])

    return <div className="page-content">
        <div className="container">
            {event === null ?
                <div className="h4">{t('status.loading')}...</div>
                :
                event === undefined ?
                    <div className="h4">{t('error.eventNotFound')}</div>
                    :
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="mb-1">{event.title}</h1>
                                        <span className="text-primary">{t('event.card.by')} {event.organizerTitle}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5 pb-2">
                                <div className="row gx-6 align-items-center">
                                    <div className="col-12 col-md mb-3 mb-md-2">
                                        <div className="d-flex">
                                            <CalendarIcon className="icon-primary me-2"/>
                                            <span className="mt-1">
                                                {eventDate}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="mb-1">{t('statistic.title')}</h3>
                                        {stats === null ?
                                            <p>{t('status.loading')}...</p>
                                            :
                                            stats === undefined ?
                                                <p>{t('error.statsNotFound')}</p>
                                                :
                                                <>
                                                    <p>{t('statistic.whitelistedTickets')}: {stats.whitelistedTickets}</p>
                                                    <p>{t('statistic.usedTickets')}: {stats.usedTickets}</p>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}

export default EventStatistic
