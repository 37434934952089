import React from 'react'
import {IconProps} from '../../store/types'

const EditIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.72 4.1a2.2 2.2 0 0 1 3.12 0l2.07 2.06a2.2 2.2 0 0 1 0 3.11l-9.78 9.78c-.3.3-.7.52-1.12.6l-4.36.88a1 1 0 0 1-1.18-1.18L4.35 15c.09-.42.3-.81.6-1.12l9.77-9.78Zm1.7 1.4a.2.2 0 0 0-.28 0l-1.36 1.37 2.35 2.35 1.36-1.36a.2.2 0 0 0 0-.29l-2.07-2.06Zm-.7 5.13-2.35-2.35-7 7a.2.2 0 0 0-.06.1l-.58 2.9 2.89-.59a.2.2 0 0 0 .1-.05l7-7Z"></path>
    </svg>
}

export default EditIcon
