import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {ethers} from 'ethers'
import {getFromStorage, setToStorage} from '../../store/storage'

const Referral = () => {
    const [searchParams] = useSearchParams('')
    const referrer = searchParams.get('ref') || ''
    const ref = getFromStorage('referrer')

    if (!ref && ethers.utils.isAddress(referrer)) {
        setToStorage('referrer', referrer)
    }

    return <></>
}

export default Referral
