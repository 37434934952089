import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {useTranslation} from 'react-i18next'
import {AppDispatch} from '../../store/store'
import {APP_URL} from '../../utils/constants'
import {CalendarIcon, EditIcon} from '../icons'
import {getDateRangeString, getDateTime} from '../../utils/functions'
import {getEvent, changeEventModeration, requestEvent, setEvent} from '../../store/calendarSlice'
import {getUser} from '../../store/authSlice'
import {CheckboxElement} from '../elements'

const Event = () => {
    const {t} = useTranslation()
    const {eventId} = useParams()
    const event = useSelector(getEvent)
    const user = useSelector(getUser)
    let startDate = ''
    let startTime = ''
    let endDate = ''
    let endTime = ''
    let eventDate = ''
    if (event) {
        eventDate = getDateRangeString(event.startTime, event.endTime)
        let datetime = getDateTime(event.startTime, true)
        startDate = datetime.date
        startTime = datetime.time
        datetime = getDateTime(event.endTime, true)
        endDate = datetime.date
        endTime = datetime.time
    }

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const id = Number(eventId)
        if (!isNaN(id) && event === null) {
            dispatch(requestEvent(id))
        }
    }, [event, eventId])
    useEffect(() => {
        return () => {
            dispatch(setEvent(null))
        }
    }, [eventId])

    const publishHandler = () => {
        if (!event) {
            return
        }

        dispatch(changeEventModeration())
    }

    return <div className="page-content">
        <div className="container">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={'/calendar'}>{t('section.calendar')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {event ? event.title : t('section.event')}
                    </li>
                </ol>
            </nav>
        </div>
        <div className="container">
            {event === null ?
                <div className="h4">{t('status.loading')}...</div>
                :
                event === undefined ?
                    <div className="h4">{t('error.eventNotFound')}</div>
                    :
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="mb-1">{event.title}</h1>
                                        {event.types.map(item => (
                                            <span className="badge badge-sm badge-outline-primary me-1" key={item.id}>
                                                {item.title}
                                            </span>
                                        ))}
                                    </div>
                                    {user && user.manager ?
                                        <div className="col-12 col-md-auto">
                                            <Link
                                                to={`/calendar/events/${eventId}/edit`}
                                                className={'btn btn-sm btn-outline-primary w-100'}
                                            >
                                                <span className="me-2"><EditIcon/></span>
                                                <span>{t('button.edit')}</span>
                                            </Link>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            {user && user.manager ?
                                <div className="mb-5">
                                    <CheckboxElement
                                        checked={event.moderation}
                                        onChange={publishHandler}
                                        label={t('form.label.published')}
                                    />
                                </div>
                                :
                                null
                            }
                            <div className="mb-5 pb-2">
                                <div className="row gx-6 align-items-center">
                                    <div className="col-12 col-md mb-3 mb-md-2">
                                        <div className="d-flex">
                                            <CalendarIcon className="icon-primary me-2"/>
                                            <span className="mt-1">
                                                {eventDate}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-auto mb-2">
                                        <AddToCalendarButton
                                            label={t('button.addToCalendar')}
                                            name={event.title}
                                            description={`${APP_URL}/calendar/events/${event.id}`}
                                            options={['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo']}
                                            startDate={startDate}
                                            startTime={startTime}
                                            endDate={endDate}
                                            endTime={endTime}
                                            useUserTZ={true}
                                            customCss="/css/atcb.min.css"
                                            lightMode="dark"
                                            hideCheckmark="true"
                                            buttonStyle="custom"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-6">
                                {event.description?.split(/\r?\n/).map(
                                    (item, index) => (<p key={index}>{item}</p>)
                                )}
                            </div>
                            <div className="mb-6">
                                {event.links.map((item, index) => (
                                    <p>
                                        <a href={item.url} target={'_blank'} rel={'noreferrer'} key={index}>
                                            {item.title}
                                        </a>
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
            }
        </div>
    </div>
}

export default Event
