import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {Web3OnboardProvider, init} from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import {AccountCenterOptions} from '@web3-onboard/core/dist/types'
import {Buffer} from 'buffer'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import {getChainsForOnboard} from './utils/functions'
import {ThemeProvider} from './context'
import {APP_URL, WALLETCONNECT_ID} from './utils/constants'
import {Authorization} from './components/auth'
import './i18n/config'

import app_icon from './static/img/getpass-logo.svg'

window.Buffer = window.Buffer || Buffer

const injected = injectedModule()
const walletConnect = walletConnectModule({
    projectId: WALLETCONNECT_ID,
    dappUrl: APP_URL,
})

const wallets = [
    injected,
    walletConnect,
]
const appMetadata = {
    name: 'MyShCh.io app',
    icon: app_icon,
    description: 'NFT 2.0 tickets. wNFT gifts. Web 3.0 accesses and certificates',
    recommendedInjectedWallets: [
        {name: 'MetaMask', url: 'https://metamask.io'},
        {name: 'Coinbase', url: 'https://wallet.coinbase.com/'}
    ]
}
const accountCenter: AccountCenterOptions = {
    desktop: {enabled: false},
    mobile: {enabled: false},
}
const web3Onboard = init({
    wallets,
    chains: getChainsForOnboard(),
    appMetadata,
    accountCenter,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <Provider store={store}>
        <Web3OnboardProvider web3Onboard={web3Onboard}>
            <ThemeProvider>
                <Authorization/>
                <App/>
            </ThemeProvider>
        </Web3OnboardProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
