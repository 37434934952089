import React from 'react'
import Tippy from '@tippyjs/react'
import {ethers} from 'ethers'
import {useTranslation} from 'react-i18next'
import {DBEventWithOrganizer} from '../../store/types'
import {compactString, getMonthList} from '../../utils/functions'

interface PropsType {
    event: DBEventWithOrganizer
}

const EventCard = ({event}: PropsType) => {
    const {t} = useTranslation()
    const date = event.startTime
    const day = date.getDate()
    const month = getMonthList()[date.getUTCMonth()].name
    const year = date.getFullYear()
    const dateStr = date.toString()
    const dayOfWeek = dateStr.substring(0, dateStr.indexOf(' '))
    const pos = dateStr.indexOf(':')
    const time = dateStr.substring(pos - 2, pos + 3)
    const timezone = dateStr.substring(dateStr.indexOf('GMT'))

    return <div className="event-preview">
        <div>
            <div className="d-flex mb-4">
                <span className="badge badge-sm text-bg-primary me-1">{day} {month} {year}</span>
                {event.showcaseType ?
                    <span className="badge badge-sm badge-outline-primary me-1">{t(`event.type.${event.showcaseType}`)}</span>
                    :
                    null
                }
            </div>
            <div className="mb-4">
                <div className="h3 mb-1">{event.title}</div>
                <div className="d-flex text-primary small">
                    <span className="me-1">{t('event.card.by')} </span>
                    <Tippy
                        content={event.organizerTitle}
                        appendTo={document.getElementsByClassName("wrapper")[0]}
                        trigger='mouseenter'
                        interactive={false}
                        arrow={false}
                        maxWidth={512}
                    >
                            <span className="text-truncate">
                                {ethers.utils.isAddress(event.organizerTitle) ?
                                    compactString(event.organizerTitle)
                                    :
                                    event.organizerTitle
                                }
                            </span>
                    </Tippy>
                </div>
            </div>
        </div>
        <div>
            <div>{dayOfWeek}, {time}</div>
            <small className="text-muted">{timezone}</small>
        </div>
    </div>
}

export default EventCard
