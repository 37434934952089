import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getWalletAddress, setModalCreateLazyDisplay} from '../../store/appSlice'
import {ButtonElement, DateElement, InputElement} from '../elements'
import {AppDispatch} from '../../store/store'
import {checkUrlNameString} from '../../utils/functions'
import {CloseIcon} from '../icons'
import {setDisplayParams} from '../../store/lazyMintingSlice'

interface propsType {
    show: boolean
}

const ModalCreateLazyDisplay = (props: propsType) => {
    const {t} = useTranslation()
    const modalFade = useRef(null)
    const [disableDate, setDisableDate] = useState<Date | null>(new Date())
    const [disableError, setDisableError] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [displayError, setDisplayError] = useState('')
    const [enableDate, setEnableDate] = useState<Date | null>(new Date())
    const [enableError, setEnableError] = useState('')
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    const closeModal = () => {
        dispatch(setModalCreateLazyDisplay(false))
    }
    const createDisplay = () => {
        let error = false
        const name = displayName.trim()
        const enableTimestamp = enableDate ? Math.floor(enableDate.getTime() / 1000) : 0
        const disableTimestamp = disableDate ? Math.floor(disableDate.getTime() / 1000) : 0

        if (name === '') {
            error = true
            setDisplayError(t('error.mustEnter', {name: t('form.label.name')}))
        } else if (!checkUrlNameString(name)) {
            error = true
            setDisplayError(`${t('error.wrong', {name: t('form.label.name')})} (a-Z, 0-9, "-", ".", "_", "~")`)
        } else {
            setDisplayError('')
        }
        if (enableTimestamp <= 0) {
            error = true
            setEnableError(t('error.wrong', {name: t('form.label.enableDate')}))
        } else {
            setEnableError('')
        }
        if (disableTimestamp <= 0) {
            error = true
            setDisableError(t('error.wrong', {name: t('form.label.disableDate')}))
        } else if (disableTimestamp <= enableTimestamp) {
            error = true
            setDisableError(t('error.mustBeGreater', {name: t('form.label.disableDate'), value: t('form.label.enableDate')}))
        } else {
            setDisableError('')
        }

        if (error || !walletAddress) {
            return
        }
        dispatch(setDisplayParams({
            displayName,
            beneficiary: walletAddress,
            enableAfter: enableTimestamp,
            disableAfter: disableTimestamp,
        }))
    }

    if (!props.show) {
        return null
    }

    return <div
        className={`modal modal-lg fade show`}
        ref={modalFade}
        tabIndex={-1}
        aria-labelledby="Create a new showcase"
        aria-hidden="true"
        style={{display: 'block'}}
        onMouseDown={(e) => {
            if (e.target === modalFade.current) {
                closeModal()
            }
        }}
    >
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{t('modal.title.newSmartShowcase')}</h2>
                    <button className="btn p-2 btn-with-opacity btn-modal-close" onClick={closeModal}>
                        <CloseIcon/>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4">
                                <InputElement
                                    errorText={displayError}
                                    label={<>{t('form.label.name')} <span
                                        className="text-muted">(a-Z, 0-9, "-", ".", "_", "~")</span></>}
                                    value={displayName}
                                    onChange={(value) => {
                                        if (checkUrlNameString(value)) {
                                            setDisplayName(value)
                                            setDisplayError('')
                                        } else {
                                            setDisplayError(`${t('error.wrong', {name: t('form.label.name')})} (a-Z, 0-9, "-", ".", "_", "~")`)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={enableDate}
                                    onChange={setEnableDate}
                                    label={t('form.label.enableDate')}
                                    errorText={enableError}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="mb-4">
                                <DateElement
                                    value={disableDate}
                                    onChange={setDisableDate}
                                    label={t('form.label.disableDate')}
                                    errorText={disableError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-end">
                    <ButtonElement
                        additionalClass={'flex-grow-1 flex-lg-grow-0'}
                        onClick={createDisplay}
                    >{t('button.create')}</ButtonElement>
                </div>
            </div>
        </div>
    </div>
}

export default ModalCreateLazyDisplay
