import React, {ReactNode} from 'react'

interface propsType {
    additionalClass?: string
    centered?: boolean
    children: ReactNode
    type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning'
}

const AlertElement = (props: propsType) => {
    return <div className={`alert alert-${props.type || 'primary'} ${props.centered ? 'text-center' : ''} ${props.additionalClass || ''}`}>
        {props.children}
    </div>
}

export default AlertElement
