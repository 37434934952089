import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {isEqual} from 'lodash'
import {AppDispatch} from '../../store/store'
import {getEvents, requestEventsWithFilter, setEvents} from '../../store/eventsSlice'
import {CheckboxGroupElement, DropdownSelector} from '../elements'
import {getMonthList} from '../../utils/functions'
import {
    getAllEvents,
    getEvents as getCalendarEvents,
    getEventTypesList, getEventTypesObject,
    requestEventsWithFilter as requestCalendarEventsWithFilter, requestEventTypes,
    setEvents as setCalendarEvents
} from '../../store/calendarSlice'
import {DBCalendarEvent, DBEventWithOrganizer, IDropdownItem, isDBEventWithOrganizer} from '../../store/types'
import {getUser} from '../../store/authSlice'
import {LoadingIcon} from '../icons'
import {MAX_EVENTS_IN_CALENDAR_CELL} from '../../utils/constants'

const CalendarByMonth = () => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams('')
    let month = NaN
    let year = NaN
    if (searchParams.has('month') && searchParams.get('month') !== '') {
        month = Number(searchParams.get('month'))
    }
    if (searchParams.has('year') && searchParams.get('year') !== '') {
        year = Number(searchParams.get('year'))
    }
    const currentYear = (new Date()).getFullYear()
    let daysCount = NaN
    if (!isNaN(year) && !isNaN(month)) {
        daysCount = new Date(year, month + 1, 0).getDate()
    }
    let typeIds: number[] = []
    for (let item of searchParams.get('typeIds')?.split(',') || []) {
        if (item === '') {
            continue
        }

        const id = Number(item)
        if (!isNaN(id)) {
            typeIds.push(id)
        }
    }
    const [checkedTypes, setCheckedTypes] = useState<number[]>(typeIds)
    const [selectedMonth, setSelectedMonth] = useState(isNaN(month) ? (new Date()).getUTCMonth() : month)
    const [selectedYear, setSelectedYear] = useState(isNaN(year) ? currentYear : year)
    let yearsArray: IDropdownItem[] = []
    for (let i = 2022; i < currentYear + 3; i++) {
        yearsArray.push({id: i, name: i.toString()})
    }
    const calendarEvents = useSelector(getCalendarEvents)
    const events = useSelector(getEvents)
    const allEvents = useSelector(getAllEvents, shallowEqual)
    const eventTypeObject = useSelector(getEventTypesObject)
    const typeList = useSelector(getEventTypesList)
    const user = useSelector(getUser)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestEventTypes())
    }, [])
    useEffect(() => {
        if (events) {
            dispatch(setEvents(null))
        }
        if (calendarEvents) {
            dispatch(setCalendarEvents(null))
        }

        searchParams.set('month', selectedMonth.toString())
        searchParams.set('year', selectedYear.toString())
        if (checkedTypes.length > 0) {
            searchParams.set('typeIds', checkedTypes.join(','))
        } else {
            searchParams.delete('typeIds')
        }
        setSearchParams(searchParams)
        dispatch(requestEventsWithFilter({month: selectedMonth, typeIds: checkedTypes, year: selectedYear}))
        dispatch(requestCalendarEventsWithFilter({month: selectedMonth, typeIds: checkedTypes, year: selectedYear}))
    }, [selectedMonth, selectedYear, checkedTypes])
    useEffect(() => {
        if (isEqual(eventTypeObject, {})) {
            return
        }

        let types = checkedTypes.filter(item => !!eventTypeObject[item])
        if (types.length !== checkedTypes.length) {
            setCheckedTypes(types)
        }
    }, [checkedTypes, eventTypeObject])

    const calendarTable = useMemo(() => {
        if (isNaN(year) || isNaN(month) || isNaN(daysCount) || !allEvents) {
            return <LoadingIcon/>
        }

        let result: (DBCalendarEvent | DBEventWithOrganizer)[][] = []
        for (let day = 0; day < daysCount; day++) {
            let dayEvents: (DBCalendarEvent | DBEventWithOrganizer)[] = []
            const start = new Date(year, month, day + 1)
            const end = new Date(year, month, day + 2)
            let i = 0
            while (allEvents.length > i) {
                const event = allEvents[i]
                if (event.startTime >= end) {
                    break
                }

                if (event.endTime >= start) {
                    dayEvents.push(event)
                }
                i++
            }
            result.push(dayEvents)
        }
        return result.map((item, index) => {
            const date = new Date(year, month, index + 1)
            const dayStr = date.getDate()
            const monthStr = getMonthList()[date.getMonth()].name
            const yearStr = date.getFullYear()
            return <React.Fragment key={index}>
                <div className={'row'}>
                    <div className={'col'}>{dayStr} {monthStr} {yearStr}</div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        {item.map((event, idx) => {
                            if (idx < MAX_EVENTS_IN_CALENDAR_CELL) {
                                if (isDBEventWithOrganizer(event)) {
                                    return <p key={idx}>
                                        {event.startTime >= date ? `${event.startTime.toLocaleString('ru', {hour: '2-digit', minute: '2-digit'})}` : '00:00'}
                                        {' '}
                                        <Link to={`/event/${event.organizerUrl}/${event.url}`}>{event.title}</Link>
                                    </p>
                                } else {
                                    return <p key={idx}>
                                        {event.startTime >= date ? `${event.startTime.toLocaleString('ru', {hour: '2-digit', minute: '2-digit'})}` : '00:00'}
                                        {' '}
                                        <Link to={`/calendar/events/${event.id}`}>{event.title}</Link>
                                    </p>
                                }
                            }
                            return null
                        })}
                        {item.length > MAX_EVENTS_IN_CALENDAR_CELL ?
                            <p>{t('word.and')} {item.length - MAX_EVENTS_IN_CALENDAR_CELL} {t('word.more')}</p>
                            :
                            null
                        }
                        {item.length > 0 ?
                            <p><Link to={`/calendar?from=${date.getTime()}&to=${(date.getTime() + 86399999)}`}>{t('word.all')}</Link></p>
                            :
                            <p>{t('error.eventsNotFound')}</p>
                        }
                    </div>
                </div>
            </React.Fragment>
        })
    }, [allEvents])

    const eventTypesHandler = (id: any, checked: boolean) => {
        if (!checked) {
            setCheckedTypes(checkedTypes.filter((item) => item !== id))
        } else if (checkedTypes.indexOf(id) < 0) {
            setCheckedTypes([...checkedTypes, id])
        }
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t('header.calendarEvents')}</h1>
            <div className="row gx-3">
                {user && user.manager ?
                    <div className="col-md-auto me-md-0 ms-md-auto mb-4 mb-md-0 pb-4 pb-md-0 mt-n3 mt-md-0 order-md-4">
                        <Link to={'/calendar/addEvent'} className={'btn btn-sm btn-primary w-100'}>
                            {t('button.addThirdPartyEvent')}
                        </Link>
                    </div>
                    :
                    null
                }
                <div className="col-auto mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedYear}
                        list={yearsArray}
                        setItem={setSelectedYear}
                        button
                    />
                </div>
                <div className="col-auto mb-xl-3">
                    <DropdownSelector
                        currentItem={selectedMonth}
                        list={getMonthList()}
                        setItem={setSelectedMonth}
                        button
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <CheckboxGroupElement
                            checkedList={checkedTypes}
                            list={typeList}
                            onChange={eventTypesHandler}
                        />
                    </div>
                </div>
            </div>
            {calendarTable}
        </div>
    </div>
}

export default CalendarByMonth
