import React from 'react'
import {Link} from 'react-router-dom'
import {DBEventWithOrganizer} from '../../store/types'
import {EventCard} from './index'

interface PropsType {
    event: DBEventWithOrganizer
    link: string
}

const EventCardWithLink = ({event, link}: PropsType) => {
    return <div className="col-md-6 col-lg-4 col-xl-3 mb-4">
        <Link to={link}><EventCard event={event}/></Link>
    </div>
}

export default EventCardWithLink
