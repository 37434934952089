import React from 'react'
import {IconProps} from '../../store/types'

const ExternalLinkSmallIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M17.59 5H15a1 1 0 1 1 0-2h5a1 1 0 0 1 1 1v4.5a1 1 0 1 1-2 0V6.41l-6.8 6.8a1 1 0 0 1-1.4-1.42L17.58 5ZM7 5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h10a2 2 0 0 0 2-2v-4a1 1 0 0 1 2 0v4a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h4a1 1 0 1 1 0 2H7Z"></path>
    </svg>
}

export default ExternalLinkSmallIcon
