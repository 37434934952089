import React from 'react'
import {IconProps} from '../../store/types'

const CopyIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.8 3.4c-.77 0-1.4.63-1.4 1.4V12c0 .77.63 1.4 1.4 1.4H12c.77 0 1.4-.63 1.4-1.4V4.8c0-.77-.63-1.4-1.4-1.4H4.8ZM1.4 4.8a3.4 3.4 0 0 1 3.4-3.4H12a3.4 3.4 0 0 1 3.4 3.4V12a3.4 3.4 0 0 1-3.4 3.4H4.8A3.4 3.4 0 0 1 1.4 12V4.8ZM17 9.6a1 1 0 0 1 1-1h2.4c1.22 0 2.2.98 2.2 2.2v8.8a3 3 0 0 1-3 3h-8.8a2.2 2.2 0 0 1-2.2-2.2V18a1 1 0 1 1 2 0v2.4c0 .11.09.2.2.2h8.8a1 1 0 0 0 1-1v-8.8a.2.2 0 0 0-.2-.2H18a1 1 0 0 1-1-1Z"/>
    </svg>
}

export default CopyIcon
