import React, {ReactNode, useState} from 'react'
import {ChevronDownIcon} from '../icons'

interface IProps {
    text: ReactNode
    title: string
}

const HiddenTextBlock = (props: IProps) => {
    const [blockOpened, setBlockOpened] = useState(false)

    const clickHandler = () => {
        setBlockOpened(!blockOpened)
    }

    return <div className="mb-4">
        <button className={`btn btn-link px-0 ${blockOpened ? 'collapse-opened' : ''}`} onClick={clickHandler}>
            <span className="me-2">{props.title}</span>
            <ChevronDownIcon/>
        </button>
        <div className={`collapse ${blockOpened ? 'show' : ''}`}>
            <div className="collapse-content">
                {props.text}
            </div>
        </div>
    </div>
}

export default HiddenTextBlock
