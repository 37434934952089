import React from 'react'
import {IconProps} from '../../store/types'

const TagIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24"
                viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.65 3.4a.77.77 0 0 0-.6.22l-9.43 9.42c-.3.3-.3.79 0 1.09l6.25 6.25c.3.3.79.3 1.09 0l9.42-9.42a.77.77 0 0 0 .22-.6l-.45-5.8a.77.77 0 0 0-.7-.71l-5.8-.45Zm-2.02-1.19a2.77 2.77 0 0 1 2.17-.8l5.8.44a2.77 2.77 0 0 1 2.55 2.55l.44 5.8c.06.8-.23 1.6-.8 2.17l-9.42 9.42a2.77 2.77 0 0 1-3.91 0L2.2 15.54a2.77 2.77 0 0 1 0-3.91l9.42-9.42Zm3.72 5.44a1 1 0 1 1 1 1 1 1 0 0 1-1-1Z"></path>
    </svg>
}

export default TagIcon
