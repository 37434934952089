import React from 'react'
import {IconProps} from '../../store/types'

const YoutubeIcon = (props: IconProps) => {
    return <svg className={props.className} width="25" height="25" viewBox="0 0 25 25" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 12.5a12.5 12.5 0 1 1 25 0 12.5 12.5 0 0 1-25 0Zm19.99 3.77c.32-1.22.32-3.76.32-3.76s.01-2.54-.32-3.77c-.18-.67-.7-1.2-1.38-1.37-1.22-.34-6.11-.34-6.11-.34s-4.89 0-6.11.32A2 2 0 0 0 5 8.75C4.7 9.96 4.7 12.5 4.7 12.5s0 2.55.32 3.76c.18.67.7 1.2 1.38 1.37 1.23.34 6.11.34 6.11.34s4.89 0 6.11-.32c.67-.18 1.2-.71 1.38-1.38ZM15 12.5l-4.07 2.34v-4.68l4.07 2.34Z"></path>
    </svg>
}

export default YoutubeIcon
