import {ReactNode} from 'react'
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import {ethers} from 'ethers'
import {AbiItem} from 'web3-utils'
import i18next from 'i18next'
import {RootState} from './store'
import {getDisplayHash} from '../utils/functions'
import {
    checkResponse, setModalAddTicketsToLazyShowcase, setModalAddTicketsToShowcase,
    setModalCreateLazyDisplay,
    setModalEditLazyDisplay,
    setModalError,
    setModalSendTransactions, setRedirectPath
} from './appSlice'
import {_AssetType, API_URL, CHAINS, NULL_ADDRESS} from '../utils/constants'
import Erc20Abi from '../utils/abi/erc20.json'
import {setTickets} from './ticketsSlice'
import {
    ITransaction,
    IPrice,
    IAssetItem,
    IItemOnSale,
    IDisplay,
    IDisplayParams, SliceResponse, DBEventWithOrganizer, ISendTransaction, DBShowcase, DBCalendarEventType
} from './types'
import {getFromStorage} from './storage'

interface LazyMintingState {
    addedDisplayName: string | null
    discounts: IDiscounts
    displayName: string | null
    displays: Display[] | null
    showcase: IDisplay | null
    showcaseEvents: DBEventWithOrganizer[] | null
}

interface IBatchItemsParams {
    prices: IPrice[]
}

interface IDiscounts {
    [key: string]: number   //key: `${contract}-${tokenId}`
}

interface Display {
    disableAfter: number
    enableAfter: number
    hash: string
    id: number
    name: string
    contract: string
}

interface IEditAssetItemPriceParams {
    oldPrices: IPrice[]
    newPrices: IPrice[]
    assetItem: IAssetItem
}

const initialState: LazyMintingState = {
    addedDisplayName: null,
    discounts: {},
    displayName: null,
    displays: null,
    showcase: null,
    showcaseEvents: null,
}

/*
export const addBatchItemsToDisplayWithSamePrice = createAsyncThunk(
    'lazyMinting/addBatchItemsToDisplayWithSamePrice',
    async (params: IBatchItemsParams, {dispatch, getState}): Promise<boolean> => {
        const {prices} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {displayName, selectedTickets} = state.lazyMinting

        if (!currentNetwork || !web3 || !walletAddress || !displayName || selectedTickets.length === 0) {
            return false
        }

        for (let item of prices) {
            if (item.payWith !== NULL_ADDRESS) {
                try {
                    const erc20Contract = new web3.eth.Contract(Erc20Abi as AbiItem[], item.payWith)
                    await erc20Contract.methods.decimals().call()
                    await erc20Contract.methods.symbol().call()
                } catch (e) {
                    console.log(e)
                    dispatch(setModalError({
                        title: 'Wrong custom coin contract',
                        text: 'Contract not support some coin\'s methods',
                        buttons: ['close'],
                    }))
                    return false
                }
            }
        }

        let transactions: ISendTransaction[] = []
        const hash = getDisplayHash(displayName)
        let items: IAssetItem[] = []
        let contracts: string[] = []
        for (let item of selectedTickets) {
            if (item.assetType !== _AssetType.ERC721) {
                continue
            }

            if (contracts.indexOf(item.contract) < 0) {
                contracts.push(item.contract)
                try {
                    const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, item.contract)
                    if (!(await contract.methods.isApprovedForAll(walletAddress, CHAINS[currentNetwork].lazyMintingContract).call())) {
                        const method = contract.methods.setApprovalForAll(CHAINS[currentNetwork].lazyMintingContract, true)
                        const encodedABI = method.encodeABI()
                        transactions.push({
                            trx: {
                                from: walletAddress,
                                to: item.contract,
                                data: encodedABI,
                            },
                            title: 'Set approval for all tickets',
                        })
                    }
                    if (!(await contract.methods.minters(CHAINS[currentNetwork].lazyMintingPriceModel).call())) {
                        const method = contract.methods.setMinterStatus(CHAINS[currentNetwork].lazyMintingPriceModel, true)
                        const encodedABI = method.encodeABI()
                        transactions.push({
                            trx: {
                                from: walletAddress,
                                to: item.contract,
                                data: encodedABI,
                            },
                            title: 'Set approval for mint tickets',
                        })
                    }
                } catch (e) {
                    console.log(e)
                    continue
                }
            }

            items.push({
                asset: {assetType: item.assetType, contractAddress: item.contract},
                tokenId: item.tokenId,
                amount: 0
            })
        }

        if (items.length === 0) {
            dispatch(setModalError({
                title: 'Wrong data',
                text: 'Tickets not selected or wrong tickets standard',
                buttons: ['close'],
            }))
            return false
        }

        const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
        const method = contract.methods.addBatchItemsToDisplayWithSamePrice(hash, items, prices)
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].lazyMintingContract,
                data: encodedABI,
            },
            title: 'Add items to display',
            successfulSendingCallback: () => {
                dispatch(setTickets(null))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)
*/

export const addItemsToDisplay = createAsyncThunk(
    'lazyMinting/addItemsToDisplay',
    async (prices: IPrice[], {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, modalAddTicketsToLazyShowcase: ticket, walletAddress, web3} = state.app
        const {displayName} = state.lazyMinting

        if (
            !currentNetwork || !web3 || !walletAddress || !displayName || !ticket ||
            ticket.assetType !== _AssetType.ERC721
        ) {
            return false
        }

        for (let item of prices) {
            if (item.payWith !== NULL_ADDRESS) {
                try {
                    const erc20Contract = new web3.eth.Contract(Erc20Abi as AbiItem[], item.payWith)
                    await erc20Contract.methods.decimals().call()
                    await erc20Contract.methods.symbol().call()
                } catch (e) {
                    console.log(e)
                    dispatch(setModalError({
                        title: i18next.t('error.wrongCustomCoinContract'),
                        text: i18next.t('error.wrongCustomCoinContractText'),
                        buttons: ['close'],
                    }))
                    return false
                }
            }
        }

        let transactions: ISendTransaction[] = []
        const hash = getDisplayHash(displayName)
        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].nftFactoryImplContract721Abi, ticket.contract)
            if (!(await contract.methods.isApprovedForAll(walletAddress, CHAINS[currentNetwork].lazyMintingContract).call())) {
                const method = contract.methods.setApprovalForAll(CHAINS[currentNetwork].lazyMintingContract, true)
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: ticket.contract,
                        data: encodedABI,
                    },
                    title: i18next.t('action.setApprovalForTickets'),
                })
            }
            if (!(await contract.methods.minters(CHAINS[currentNetwork].lazyMintingPriceModel).call())) {
                const method = contract.methods.setMinterStatus(CHAINS[currentNetwork].lazyMintingPriceModel, true)
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: ticket.contract,
                        data: encodedABI,
                    },
                    title: i18next.t('action.setApprovalForMintTickets'),
                })
            }
        } catch (e) {
            console.log(e)
            dispatch(setModalError({
                title: i18next.t('error.wrongData'),
                text: i18next.t('error.ticketsNotSelectedOrWrongStandard'),
                buttons: ['close'],
            }))
            return false
        }

        const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
        const method = contract.methods.addItemToDisplay(
            hash,
            {
                asset: {assetType: ticket.assetType, contractAddress: ticket.contract},
                tokenId: ticket.tokenId,
                amount: 0
            },
            prices
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].lazyMintingContract,
                data: encodedABI,
            },
            title: i18next.t('action.addItemsToDisplay'),
            successfulSendingCallback: () => {
                dispatch(setTickets(null))
                dispatch(setModalAddTicketsToLazyShowcase(null))
                dispatch(setModalAddTicketsToShowcase(false))
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const buyAssetItem = createAsyncThunk(
    'lazyMinting/buyAssetItem',
    async (
        {eventLink, item, priceIndex, showcaseName, successText}: {
            eventLink: string
            item: IItemOnSale,
            priceIndex: number,
            showcaseName: string,
            successText?: ReactNode,
        },
        {dispatch, getState}
    ): Promise<boolean> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {discounts, showcase} = state.lazyMinting

        if (!currentNetwork || !web3 || !walletAddress || !showcase) {
            return false
        }

        let transactions: ISendTransaction[] = []

        let {payWith, amount} = item.prices[priceIndex]
        let d = discounts[`${item.nft.asset.contractAddress}-${item.nft.tokenId}`] || 0
        if (d > 0) {
            d = d > 10000 ? 10000 : d
            amount = amount.mul(10000 - d).div(10000)
        }
        amount = ethers.utils.parseUnits(amount.toString(), 'wei')
        if (payWith !== NULL_ADDRESS) {
            const contract = new web3.eth.Contract(Erc20Abi as AbiItem[], payWith)
            //todo: request decimals and change 'wei'
            const balance = ethers.utils.parseUnits(await contract.methods.balanceOf(walletAddress).call(), 'wei')
            if (balance.gte(amount)) {
                const allowance = await contract.methods.allowance(walletAddress, CHAINS[currentNetwork].lazyMintingContract).call()
                if (amount.gt(allowance)) {
                    const method = contract.methods.approve(CHAINS[currentNetwork].lazyMintingContract, amount)
                    const encodedABI = method.encodeABI()
                    transactions.push({
                        trx: {
                            from: walletAddress,
                            to: payWith,
                            data: encodedABI,
                        },
                        title: i18next.t('action.setApproveForCoin'),
                    })
                }
            } else {
                dispatch(setModalError({
                    title: i18next.t('error.insufficientBalance'),
                    buttons: ['close'],
                }))
                return false
            }
        }

        let referrer = getFromStorage('referrer')
        if (referrer && !ethers.utils.isAddress(referrer)) {
            referrer = null
        }
        const lazyContract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
        const method = lazyContract.methods.buyAssetItem(item.nft, priceIndex, walletAddress, referrer || NULL_ADDRESS, '')
        const encodedABI = method.encodeABI()
        let trx: ITransaction = {
            from: walletAddress,
            to: CHAINS[currentNetwork].lazyMintingContract,
            data: encodedABI,
        }
        if (payWith === NULL_ADDRESS) {
            trx['value'] = amount
        }
        transactions.push({
            trx,
            title: i18next.t('action.buyItem'),
            successfulSendingCallback: () => {
                dispatch(requestShowcase({displayName: showcaseName, network: currentNetwork}))
            },
        })
        dispatch(setModalSendTransactions({
            transactions,
            actionButton: {
                action: () => {
                    dispatch(setRedirectPath(eventLink))
                }, title: i18next.t('button.goToEvent')
            },
            successButton: {
                action: () => {
                }, title: i18next.t('button.buyMore')
            },
            successText,
        }))
        return true
    }
)

export const editAssetItemPrice = createAsyncThunk(
    'lazyMinting/editAssetItemPrice',
    async (params: IEditAssetItemPriceParams, {dispatch, getState}): Promise<boolean> => {
        const {oldPrices, newPrices, assetItem} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
        let editedCnt = 0
        let deleteCnt = oldPrices.length
        for (let i = 0; i < oldPrices.length; i++) {
            if (!newPrices[i]) {
                break
            }
            deleteCnt--
            editedCnt++

            if (oldPrices[i].payWith !== newPrices[i].payWith || !oldPrices[i].amount.eq(newPrices[i].amount)) {
                const method = contract.methods.editAssetItemPriceAtIndex(assetItem, i, newPrices[i])
                const encodedABI = method.encodeABI()
                transactions.push({
                    trx: {
                        from: walletAddress,
                        to: CHAINS[currentNetwork].lazyMintingContract,
                        data: encodedABI,
//                        gasLimit: ethers.utils.parseUnits('0.01', 'gwei')
                    },
                    title: i18next.t('action.editItemPrice', {name: i + 1}),
                })
            }
        }
        for (let i = 0; i < deleteCnt; i++) {
            const method = contract.methods.removeLastPersonalPriceForAssetItem(assetItem)
            const encodedABI = method.encodeABI()
            transactions.push({
                trx: {
                    from: walletAddress,
                    to: CHAINS[currentNetwork].lazyMintingContract,
                    data: encodedABI,
//                    gasLimit: ethers.utils.parseUnits('0.01', 'gwei')
                },
                title: i18next.t('action.deleteLastPrice'),
            })
        }
        if (editedCnt < newPrices.length) {
            const method = contract.methods.addAssetItemPriceAtIndex(assetItem, newPrices.slice(editedCnt))
            const encodedABI = method.encodeABI()
            transactions.push({
                trx: {
                    from: walletAddress,
                    to: CHAINS[currentNetwork].lazyMintingContract,
                    data: encodedABI,
//                    gasLimit: ethers.utils.parseUnits('0.01', 'gwei')
                },
                title: i18next.t('action.addPrices'),
            })
        }
        if (transactions.length > 0) {
            transactions[transactions.length - 1].successfulSendingCallback = () => {
                dispatch(setShowcase(null))
            }
            dispatch(setModalSendTransactions({transactions}))
        }
        return true
    }
)

export const requestDiscount = createAsyncThunk(
    'lazyMinting/requestDiscount',
    async (
        {address, tokenId}: { address: string, tokenId: bigint },
        {getState}
    ): Promise<{ key: string, discount: number } | null> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !walletAddress || !web3) {
            return null
        }

        if (!ethers.utils.isAddress(address)) {
            console.log(`Wrong contract address: ${address}`)
            return null
        }

        try {
            const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
            const item = [[3, address], tokenId.toString(), 0]
            const referrer = getFromStorage('referrer') || NULL_ADDRESS
            const discounts = await contract.methods.getAssetItemPricesAndDiscounts(item, walletAddress, referrer, '').call()
            let discount: number = 0
            for (let item of discounts[1]) {
                discount += Number(item.dsctPercent)
            }
            return {key: `${address}-${tokenId.toString()}`, discount}
        } catch (e) {
            console.log(e)
        }
        return null
    }
)

export const requestDisplays = createAsyncThunk(
    'lazyMinting/requestDisplays',
    async (_, {dispatch, getState}): Promise<Display[]> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app
        const {addedDisplayName} = state.lazyMinting

        if (!currentNetwork || !walletAddress || !web3) {
            return []
        }

        let displays: Display[] = []
        let displayFound = false
        try {
            const result = await axios.get(`${API_URL}oracle/kiosk/user/${Number(currentNetwork)}/${walletAddress}`)
            for (let item of result.data.showcases) {
                if (item.contract.toLowerCase() === CHAINS[currentNetwork].lazyMintingContract) {
                    displays.push(item)
                    if (item.name === addedDisplayName) {
                        displayFound = true
                        dispatch(setAddedDisplayName(null))
                    }
                }
            }
        } catch (e: any) {
            console.log('Error while loading user displays')
            console.log(e)
        }
        if (!displayFound && addedDisplayName) {
            try {
                const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
                const hash = getDisplayHash(addedDisplayName)
                const display = await contract.methods.getDisplay(hash).call()
                if (Number(display.owner) !== 0) {
                    displays.push({
                        disableAfter: Number(display.disableAfter),
                        enableAfter: Number(display.disableAfter),
                        hash,
                        id: Number(hash),
                        name: addedDisplayName,
                        contract: CHAINS[currentNetwork].lazyMintingContract,
                    })
                }
            } catch (e) {
                console.log('Error while requesting user display from contract')
                console.log(e)
            }
        }
        return displays
    }
)

export const requestShowcase = createAsyncThunk(
    'lazyMinting/requestShowcase',
    async (params: { displayName: string, network?: string }, {getState}): Promise<IDisplay | null> => {
        const state = getState() as RootState
        let {currentNetwork, web3} = state.app
        const {displayName, network} = params

        if (currentNetwork && web3 && currentNetwork === network) {
            try {
                const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
                const hash = getDisplayHash(displayName)
                const result = await contract.methods.getDisplay(hash).call()
                const items: IItemOnSale[] = []
                for (let item of result.items) {
                    let prices: IPrice[] = []
                    for (let price of item.prices) {
                        prices.push({
                            amount: ethers.BigNumber.from(price.amount),
                            payWith: price.payWith.toLowerCase(),
                        })
                    }
                    items.push({
                        beneficiary: item.owner.toLowerCase(),
                        nft: {
                            amount: item.nft.amount,
                            asset: {...item.nft.asset, contractAddress: item.nft.asset.contractAddress.toLowerCase()},
                            tokenId: item.nft.tokenId,
                        },
                        owner: item.owner.toLowerCase(),
                        prices,
                    })
                }
                return {
                    beneficiary: result.beneficiary.toLowerCase(),
                    disableAfter: result.disableAfter,
                    enableAfter: result.enableAfter,
                    displayName,
                    items,
                    owner: result.owner.toLowerCase(),
                    priceModel: result.priceModel,
                }
            } catch (e) {
                console.log(e)
            }
        } else if (network) {
            try {
                const provider = ethers.getDefaultProvider(CHAINS[network].rpcUrl)
                const contract = new ethers.Contract(
                    CHAINS[network].lazyMintingContract,
                    new ethers.utils.Interface(JSON.stringify(CHAINS[network].lazyMintingContractAbi)),
                    provider
                )
                const hash = getDisplayHash(displayName)
                const result = await contract.getDisplay(hash)
                const items: IItemOnSale[] = []
                for (let item of result.items) {
                    let prices: IPrice[] = []
                    for (let price of item.prices) {
                        prices.push({
                            amount: price.amount,
                            payWith: price.payWith.toLowerCase(),
                        })
                    }
                    items.push({
                        beneficiary: item.owner.toLowerCase(),
                        nft: {
                            amount: item.nft.amount.toNumber(),
                            asset: {...item.nft.asset, contractAddress: item.nft.asset.contractAddress.toLowerCase()},
                            tokenId: item.nft.tokenId.toNumber(),
                        },
                        owner: item.owner.toLowerCase(),
                        prices,
                    })
                }
                return {
                    beneficiary: result.beneficiary.toLowerCase(),
                    disableAfter: result.disableAfter.toNumber(),
                    enableAfter: result.enableAfter.toNumber(),
                    displayName,
                    items,
                    owner: result.owner.toLowerCase(),
                    priceModel: result.priceModel,
                }
            } catch (e) {
                console.log(e)
            }
        }
        return null
    }
)

export const requestShowcaseEvents = createAsyncThunk(
    'lazyMinting/requestShowcaseEvents',
    async (params: { contract: string, displayName: string, network?: string }, {dispatch}): Promise<void> => {
        const {contract, displayName, network} = params

        let response: SliceResponse = {}
        if (!network || !CHAINS[network]) {
            response.error = {text: i18next.t('error.networkNotFound')}
        } else {
            try {
                const result = await axios.get(`${API_URL}showcases/${Number(network)}/${contract}/${getDisplayHash(displayName)}/events`)
                let events: DBEventWithOrganizer[] = []
                for (let event of result.data.events) {
                    events.push({
                        id: event.id,
                        title: event.title,
                        url: event.url,
                        description: event.description,
                        startTime: event.startTime,
                        endTime: event.endTime,
                        moderation: event.moderation,
                        organizer: event.organizer.toLowerCase(),
                        organizerId: Number(event.organizerId),
                        organizerTitle: event.organizerTitle,
                        organizerUrl: event.organizerUrl,
                        showcases: event.showcases.map((item: any): DBShowcase => ({
                            id: item.id,
                            chain: Number(item.chain_id),
                            contract: item.contract.toLowerCase(),
                            name: item.name,
                            eventId: Number(item.event_id),
                            title: item.title,
                        })),
                        types: event.types.map((item: any): DBCalendarEventType => ({
                            id: item.id,
                            title: item.title,
                        })),
                    })
                }
                response.status = result.status
                response.data = events
            } catch (e: any) {
                response.defaultData = []
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.setData = (value) => {
            dispatch(setShowcaseEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const setDisplayParams = createAsyncThunk(
    'lazyMinting/setDisplayParams',
    async (params: IDisplayParams, {dispatch, getState}): Promise<boolean> => {
        const {displayName, beneficiary, enableAfter, disableAfter} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress, web3} = state.app

        if (!currentNetwork || !web3 || !walletAddress) {
            return false
        }

        let transactions: ISendTransaction[] = []
        const contract = new web3.eth.Contract(CHAINS[currentNetwork].lazyMintingContractAbi, CHAINS[currentNetwork].lazyMintingContract)
        const method = contract.methods.setDisplayParams(
            displayName,
            beneficiary,
            enableAfter,
            disableAfter,
            CHAINS[currentNetwork].lazyMintingPriceModel,
        )
        const encodedABI = method.encodeABI()
        transactions.push({
            trx: {
                from: walletAddress,
                to: CHAINS[currentNetwork].lazyMintingContract,
                data: encodedABI,
            },
            title: params.edit ? i18next.t('button.editShowcase') : i18next.t('button.createShowcase'),
            afterSigningCallback: () => {
                dispatch(setAddedDisplayName(displayName))
            },
            successfulSendingCallback: () => {
                if (params.edit) {
                    dispatch(requestShowcase({displayName: params.displayName, network: currentNetwork}))
                    dispatch(setModalEditLazyDisplay(null))
                } else {
                    dispatch(requestDisplays())
                    dispatch(setModalCreateLazyDisplay(false))
                }
            }
        })
        dispatch(setModalSendTransactions({transactions}))
        return true
    }
)

export const lazyMintingSlice = createSlice({
    name: 'lazyMinting',
    initialState,
    reducers: {
        setAddedDisplayName: (state, action: PayloadAction<string | null>) => {
            state.addedDisplayName = action.payload
        },
        setDiscounts: (state, action: PayloadAction<IDiscounts>) => {
            state.discounts = action.payload
        },
        setDisplayName: (state, action: PayloadAction<string | null>) => {
            state.displayName = action.payload
        },
        setDisplays: (state, action: PayloadAction<Display[] | null>) => {
            state.displays = action.payload
        },
        setShowcase: (state, action: PayloadAction<IDisplay | null>) => {
            state.showcase = action.payload
        },
        setShowcaseEvents: (state, action: PayloadAction<DBEventWithOrganizer[] | null>) => {
            state.showcaseEvents = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestDiscount.fulfilled, (state, action: PayloadAction<{
            key: string,
            discount: number
        } | null>) => {
            if (action.payload) {
                state.discounts[action.payload.key] = action.payload.discount
            }
        })
        builder.addCase(requestDisplays.fulfilled, (state, action: PayloadAction<Display[] | null>) => {
            state.displays = action.payload
        })
        builder.addCase(requestShowcase.fulfilled, (state, action: PayloadAction<IDisplay | null>) => {
            state.showcase = action.payload
        })
    },
})

export const getAddedDisplayName = (state: RootState): string | null => state.lazyMinting.addedDisplayName
export const getDiscounts = (state: RootState): IDiscounts => state.lazyMinting.discounts
export const getDiscount = (key: string) => (state: RootState): number => state.lazyMinting.discounts[key] || 0
export const getDisplay = (state: RootState): Display | null => {
    const id = getDisplayId()
    if (id === null || !state.lazyMinting.displays) {
        return null
    }

    for (let item of state.lazyMinting.displays) {
        if (item.id === id) {
            return item
        }
    }
    setDisplayId(null)
    return null
}
export const getDisplayId = (): number | null => {
    const id = localStorage.getItem('lazyDisplayId')
    return id === '' ? null : Number(id)
}
export const getDisplayName = (state: RootState): string | null => state.lazyMinting.displayName
export const getDisplays = (state: RootState): Display[] | null => state.lazyMinting.displays
export const getShowcase = (state: RootState): IDisplay | null => state.lazyMinting.showcase
export const getShowcaseEvents = (state: RootState): DBEventWithOrganizer[] | null => state.lazyMinting.showcaseEvents
export const setDisplayId = (id: number | null): void => {
    localStorage.setItem('lazyDisplayId', id?.toString() || '')
}

export const {
    setAddedDisplayName,
    setDiscounts,
    setDisplayName,
    setDisplays,
    setShowcase,
    setShowcaseEvents,
} = lazyMintingSlice.actions

export default lazyMintingSlice.reducer
