import React, {useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useTranslation} from 'react-i18next'
import {CopyIcon} from '../icons'
import {COPY_HINT_TIME} from '../../utils/constants'

interface propsType {
    additionalClass?: string
    buttonText?: string
    small?: boolean
    text: string | number
}

const CopyToClipboardBlock = (props: propsType) => {
    const {t} = useTranslation()
    const [copiedHint, setCopiedHint] = useState(false)
    let className = 'btn btn-link p-1'
    if (props.small) {
        className += ' btn-sm'
    }
    if (props.additionalClass) {
        className += ` ${props.additionalClass}`
    }

    return <CopyToClipboard
        text={props.text.toString()}
        onCopy={() => {
            setCopiedHint(true)
            setTimeout(() => {
                setCopiedHint(false)
            }, COPY_HINT_TIME)
        }}
    >
        <button className={className}>
            {props.buttonText ? <span className="me-2">{props.buttonText}</span> : null}
            <CopyIcon/>
            <i className="btn-hint" style={{display: copiedHint ? 'block' : 'none'}}>{t('element.status.copied')}</i>
        </button>
    </CopyToClipboard>
}

export default CopyToClipboardBlock
