import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import i18next from 'i18next'
import {RootState} from './store'
import {API_URL} from '../utils/constants'
import {checkResponse, setRedirectPath} from './appSlice'
import {
    DBCalendarEventType,
    DBEventWithOrganizer,
    DBCalendarEvent, IDropdownItem,
    IEventByDay,
    IEventFilters, IEventTypesObject,
    ICalendarEvent,
    SliceResponse
} from './types'

interface CalendarState {
    event: DBCalendarEvent | null
    events: DBCalendarEvent[] | null
    eventTypes: DBCalendarEventType[] | null
}

const initialState: CalendarState = {
    event: null,
    events: null,
    eventTypes: null,
}

export const addEvent = createAsyncThunk(
    'calendar/addEvent',
    async (params: ICalendarEvent, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.post(`${API_URL}calendar/event`, {...params}, config)
                response.status = result.status
                response.data = null
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
        }
        response.successCallback = () => {
            dispatch(setRedirectPath('/calendar'))
        }
        dispatch(checkResponse(response))
    }
)

export const editEvent = createAsyncThunk(
    'calendar/editEvent',
    async (params: ICalendarEvent, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {event} = state.calendar

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else if (!event) {
            response.error = {text: i18next.t('error.eventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.put(`${API_URL}calendar/events/${event.id}`, {...params}, config)
                response.status = result.status
                response.data = null
            } catch (e: any) {
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.successCallback = () => {
                dispatch(setRedirectPath(`/calendar/events/${event.id}`))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const changeEventModeration = createAsyncThunk(
    'calendar/changeEventModeration',
    async (_, {getState, dispatch}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        const {event} = state.calendar

        let response: SliceResponse = {}
        if (!jwt) {
            response.error = {text: i18next.t('error.notAuthorized')}
        } else if (!event) {
            response.error = {text: i18next.t('error.eventNotFound')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.put(
                    `${API_URL}calendar/events/${event.id}/moderation`,
                    {moderation: !event.moderation},
                    config
                )
                response.status = result.status
                response.data = null
            } catch (e: any) {
                response.defaultData = null
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = (value) => {
                dispatch(setEvent(value))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestEvent = createAsyncThunk(
    'calendar/requestEvent',
    async (eventId: number, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        let response: SliceResponse = {}
        if (isNaN(eventId)) {
            response.error = {text: i18next.t('error.wrongEventId')}
        } else {
            try {
                const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
                const result = await axios.get(`${API_URL}calendar/events/${eventId}`, config)
                const event: DBCalendarEvent = {
                    ...result.data.event,
                    startTime: new Date(result.data.event.startTime),
                    endTime: new Date(result.data.event.endTime),
                }
                response.status = result.status
                response.data = event
            } catch (e: any) {
                response.defaultData = undefined
                if (e.response) {
                    response.status = e.response.status
                    response.error = {text: e.response.data.error}
                } else {
                    response.error = {text: e.message}
                }
            }
            response.setData = (value) => {
                dispatch(setEvent(value))
            }
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventsWithFilter = createAsyncThunk(
    'calendar/requestEventsWithFilter',
    async (filter: IEventFilters, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {jwt} = state.auth
        let response: SliceResponse = {}
        try {
            let queryArr: string[] = []
            if (filter.from || filter.to) {
                if (filter.from) {
                    queryArr.push(`from=${filter.from}`)
                }
                if (filter.to) {
                    queryArr.push(`to=${filter.to}`)
                }
            } else {
                if (filter.month !== undefined) {
                    queryArr.push(`month=${filter.month}`)
                }
                if (filter.year) {
                    queryArr.push(`year=${filter.year}`)
                }
            }
            if (filter.typeIds && filter.typeIds.length > 0) {
                queryArr.push(`typeIds=${filter.typeIds.join(',')}`)
            }
            const config: any = {headers: {'authorization': `Bearer ${jwt}`}}
            const result = await axios.get(`${API_URL}calendar/events?${queryArr.join('&')}`, config)
            const events: DBCalendarEvent[] = result.data.events.map((item: DBCalendarEvent) => ({
                ...item,
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
            }))
            response.status = result.status
            response.data = events
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setEvents(value))
        }
        dispatch(checkResponse(response))
    }
)

export const requestEventTypes = createAsyncThunk(
    'calendar/requestEventTypes',
    async (_, {dispatch}): Promise<void> => {
        let response: SliceResponse = {}
        try {
            const result = await axios.get(`${API_URL}calendar/eventtypes`)
            const types: DBCalendarEventType[] = result.data.types?.map((item: DBCalendarEventType) => ({
                id: item.id,
                title: item.title,
            })) || []
            response.status = result.status
            response.data = types
        } catch (e: any) {
            response.defaultData = []
            if (e.response) {
                response.status = e.response.status
                response.error = {text: e.response.data.error}
            } else {
                response.error = {text: e.message}
            }
        }
        response.setData = (value) => {
            dispatch(setEventTypes(value))
        }
        dispatch(checkResponse(response))
    }
)

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        resetState: (state) => {
            let key: keyof CalendarState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setEvent: (state, action: PayloadAction<DBCalendarEvent | null>) => {
            state.event = action.payload
        },
        setEvents: (state, action: PayloadAction<DBCalendarEvent[] | null>) => {
            state.events = action.payload
        },
        setEventTypes: (state, action: PayloadAction<DBCalendarEventType[] | null>) => {
            state.eventTypes = action.payload
        },
    },
})

export const getAllEvents = (state: RootState): (DBEventWithOrganizer | DBCalendarEvent)[] | null => {
    const events = state.events.events
    const calendarEvents = state.calendar.events
    if (!events || !calendarEvents) {
        return null
    }

    let eventIndex = 0
    let calendarIndex = 0
    let result: (DBEventWithOrganizer | DBCalendarEvent)[] = []
    while (true) {
        if (events.length <= eventIndex && calendarEvents.length <= calendarIndex) {
            break
        }
        let item: DBEventWithOrganizer | DBCalendarEvent | null = null
        if (events.length <= eventIndex) {
            item = calendarEvents[calendarIndex]
            calendarIndex++
        } else if (calendarEvents.length <= calendarIndex) {
            item = events[eventIndex]
            eventIndex++
        } else {
            if (events[eventIndex].startTime <= calendarEvents[calendarIndex].startTime) {
                item = events[eventIndex]
                eventIndex++
            } else {
                item = calendarEvents[calendarIndex]
                calendarIndex++
            }
        }
        result.push(item)
    }
    return result
}
export const getEvent = (state: RootState): DBCalendarEvent | null => state.calendar.event
export const getEvents = (state: RootState): DBCalendarEvent[] | null => state.calendar.events
export const getEventsByDay = (state: RootState): IEventByDay[] | null => {
    const events = state.events.events
    const calendarEvents = state.calendar.events
    if (!events || !calendarEvents) {
        return null
    }

    let result: IEventByDay[] = []
    let eventIndex = 0
    let calendarIndex = 0
    let currentDay = 0
    let currentMonth = 0
    let currentYear = 0
    let currentEvents: (DBEventWithOrganizer | DBCalendarEvent)[] = []
    while (true) {
        if (events.length <= eventIndex && calendarEvents.length <= calendarIndex) {
            break
        }
        let item: DBEventWithOrganizer | DBCalendarEvent | null = null
        if (events.length <= eventIndex) {
            item = calendarEvents[calendarIndex]
            calendarIndex++
        } else if (calendarEvents.length <= calendarIndex) {
            item = events[eventIndex]
            eventIndex++
        } else {
            if (events[eventIndex].startTime <= calendarEvents[calendarIndex].startTime) {
                item = events[eventIndex]
                eventIndex++
            } else {
                item = calendarEvents[calendarIndex]
                calendarIndex++
            }
        }
        const day = item.startTime.getDate()
        const month = item.startTime.getMonth()
        const year = item.startTime.getFullYear()
        if (day !== currentDay || month !== currentMonth || year !== currentYear) {
            if (currentEvents.length > 0) {
                result.push({
                    day: currentDay,
                    month: currentMonth,
                    year: currentYear,
                    events: currentEvents,
                })
            }
            currentDay = day
            currentMonth = month
            currentYear = year
            currentEvents = []
        }
        currentEvents.push(item)
    }
    if (currentEvents.length > 0) {
        result.push({
            day: currentDay,
            month: currentMonth,
            year: currentYear,
            events: currentEvents,
        })
    }
    return result
}
export const getEventTypes = (state: RootState): DBCalendarEventType[] | null => state.calendar.eventTypes
export const getEventTypesList = createSelector(
    (state: RootState) => state.calendar.eventTypes,
    (eventTypes): IDropdownItem[] => {
        let list: IDropdownItem[] = []
        for (let item of eventTypes || []) {
            list.push({id: item.id, name: item.title})
        }
        return list
    }
)
export const getEventTypesObject = createSelector(
    (state: RootState) => state.calendar.eventTypes,
    (eventTypes): IEventTypesObject => {
        let object: IEventTypesObject = {}
        for (let item of eventTypes || []) {
            object[item.id] = item
        }
        return object
    }
)

export const {
    resetState,
    setEvent,
    setEvents,
    setEventTypes,
} = calendarSlice.actions

export default calendarSlice.reducer
