import React from 'react'
import {IconProps} from '../../store/types'

const SendIcon = (props: IconProps) => {
    return <svg className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="m20.03 5.38-8.37 8.37 2.6 6.66c.1.26.47.25.56 0l5.21-15.03Zm-9.78 6.96 8.37-8.37L3.6 9.18a.3.3 0 0 0-.01.55l6.66 2.61Zm9.3-10.81a2.3 2.3 0 0 1 2.92 2.92l-5.76 16.6a2.3 2.3 0 0 1-4.3.1L9.76 14.4a.3.3 0 0 0-.17-.17L2.86 11.6a2.3 2.3 0 0 1 .08-4.3l16.6-5.77Z"></path>
    </svg>
}

export default SendIcon
