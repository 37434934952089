import React from 'react'
import {IconProps} from '../../store/types'

const FiltersIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.2 7.2a1 1 0 0 1 1-1h15.6a1 1 0 1 1 0 2H4.2a1 1 0 0 1-1-1ZM5.6 12a1 1 0 0 1 1-1h10.8a1 1 0 1 1 0 2H6.6a1 1 0 0 1-1-1Zm3.6 4.8a1 1 0 0 1 1-1h3.6a1 1 0 1 1 0 2h-3.6a1 1 0 0 1-1-1Z"></path>
    </svg>
}

export default FiltersIcon
