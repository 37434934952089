import React from 'react'
import {IconProps} from '../../store/types'

const EmptyIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24"></rect>
    </svg>
}

export default EmptyIcon
