import React, {useEffect, useMemo, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {isEqual} from 'lodash'
import {AppDispatch} from '../../store/store'
import {getEvents, requestEventsWithFilter, setEvents} from '../../store/eventsSlice'
import {CheckboxGroupElement, DateElement} from '../elements'
import {CalendarList} from './index'
import {
    getEvents as getCalendarEvents,
    getEventsByDay, getEventTypesList, getEventTypesObject,
    requestEventsWithFilter as requestCalendarEventsWithFilter, requestEventTypes,
    setEvents as setCalendarEvents
} from '../../store/calendarSlice'
import {IDropdownItem, IEventFilters} from '../../store/types'
import {getUser} from '../../store/authSlice'
import {getWalletAddress} from '../../store/appSlice'
import {ENVIRONMENT, THIRDPARTY_FILTER} from '../../utils/constants'
import {getIdsFromSearchParams} from '../../utils/functions'

const Calendar = () => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams('')
    let from = NaN
    let to = NaN
    if (searchParams.has('from') && searchParams.get('from') !== '') {
        from = Number(searchParams.get('from'))
    }
    if (searchParams.has('to') && searchParams.get('to') !== '') {
        to = Number(searchParams.get('to'))
    }
    const [checkedThirdpartyFilter, setCheckedThirdpartyFilter] = useState<number[]>(getIdsFromSearchParams(searchParams.get('thirdparty')))
    const [checkedTypes, setCheckedTypes] = useState<number[]>(getIdsFromSearchParams(searchParams.get('typeIds')))
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        !isNaN(from) ? new Date(from) : null,
        !isNaN(to) ? new Date(to) : null,
    ])
    const [firstRender, setFirstRender] = useState(true)
    const calendarEvents = useSelector(getCalendarEvents)
    const events = useSelector(getEvents)
    const eventsByDay = useSelector(getEventsByDay, shallowEqual)
    const eventTypeObject = useSelector(getEventTypesObject)
    const typeList = useSelector(getEventTypesList)
    const user = useSelector(getUser)
    const walletAddress = useSelector(getWalletAddress)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(requestEventTypes())
        requestEvents()
        setFirstRender(false)
    }, [])
    useEffect(() => {
        if (firstRender) {
            return
        }

        let [start, end] = dateRange
        if (!start && !end) {
            requestEvents()
        }
        if (start && end) {
            searchParams.set('from', start.getTime().toString())
            searchParams.set('to', end.getTime().toString())
            if (events) {
                dispatch(setEvents(null))
            }
            if (calendarEvents) {
                dispatch(setCalendarEvents(null))
            }
            requestEvents()
        } else {
            searchParams.delete('from')
            searchParams.delete('to')
        }
        setSearchParams(searchParams)
    }, [dateRange, walletAddress])
    useEffect(() => {
        if (firstRender) {
            return
        }

        if (events) {
            dispatch(setEvents(null))
        }
        if (calendarEvents) {
            dispatch(setCalendarEvents(null))
        }
        if (checkedThirdpartyFilter.length > 0) {
            searchParams.set('thirdparty', checkedThirdpartyFilter.join(','))
        } else {
            searchParams.delete('thirdparty')
        }
        if (checkedTypes.length > 0) {
            searchParams.set('typeIds', checkedTypes.join(','))
        } else {
            searchParams.delete('typeIds')
        }
        setSearchParams(searchParams)
        requestEvents()
    }, [checkedThirdpartyFilter, checkedTypes])
    useEffect(() => {
        if (isEqual(eventTypeObject, {})) {
            return
        }

        let types = checkedTypes.filter(item => !!eventTypeObject[item])
        if (types.length !== checkedTypes.length) {
            setCheckedTypes(types)
        }
    }, [checkedTypes, eventTypeObject])

    const calendarList = useMemo(() => {
        return <CalendarList events={eventsByDay}/>
    }, [eventsByDay])

    const thirdpartyFilterList: IDropdownItem[] = []
    for (let key in THIRDPARTY_FILTER) {
        if (isNaN(Number(key))) {
            thirdpartyFilterList.push({id: THIRDPARTY_FILTER[key], name: t(`calendar.filter.${key}`)})
        }
    }
    const dateHandler = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates
        setDateRange([start, end ? new Date(end.getTime() + 86399999) : null])
    }
    const eventTypesHandler = (id: any, checked: boolean) => {
        if (!checked) {
            setCheckedTypes(checkedTypes.filter((item) => item !== id))
        } else if (checkedTypes.indexOf(id) < 0) {
            setCheckedTypes([...checkedTypes, id])
        }
    }
    const requestEvents = () => {
        let [start, end] = dateRange
        let filter: IEventFilters = {}
        if (start && end) {
            filter.from = start.getTime()
            filter.to = end.getTime()
        }
        filter.typeIds = checkedTypes
        if (checkedThirdpartyFilter.length === 0 || checkedThirdpartyFilter.indexOf(THIRDPARTY_FILTER.myshch) >= 0) {
            dispatch(requestEventsWithFilter(filter))
        } else {
            dispatch(setEvents([]))
        }
        if (checkedThirdpartyFilter.length === 0 || checkedThirdpartyFilter.indexOf(THIRDPARTY_FILTER.thirdparty) >= 0) {
            dispatch(requestCalendarEventsWithFilter(filter))
        } else {
            dispatch(setCalendarEvents([]))
        }
    }
    const thirdpartyFilterHandler = (id: any, checked: boolean) => {
        if (!checked) {
            setCheckedThirdpartyFilter(checkedThirdpartyFilter.filter((item) => item !== id))
        } else if (checkedThirdpartyFilter.indexOf(id) < 0) {
            setCheckedThirdpartyFilter([...checkedThirdpartyFilter, id])
        }
    }

    return <div className="page-content">
        <div className="container">
            <h1>{t('header.calendarEvents')}</h1>
            <div className="row gx-3">
                {user && user.manager ?
                    <div className="col-md-auto me-md-0 ms-md-auto mb-4 mb-md-0 pb-4 pb-md-0 mt-n3 mt-md-0 order-md-4">
                        <Link to={'/calendar/addEvent'} className={'btn btn-sm btn-primary w-100'}>
                            {t('button.addThirdPartyEvent')}
                        </Link>
                    </div>
                    :
                    null
                }
                <div className="col-3 mb-xl-3">
                    <DateElement
                        onChange={dateHandler}
                        value={dateRange}
                        selectsRange={true}
                    />
                </div>
                {ENVIRONMENT !== 'prod' ?
                    <div className="col-auto mb-xl-3">
                        <Link to={'/calendar/bymonth'} className={'btn btn-sm btn-primary w-100'}>
                            {t('button.calendarByMonth')}
                        </Link>
                    </div>
                    :
                    null
                }
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <CheckboxGroupElement
                            checkedList={checkedTypes}
                            list={typeList}
                            onChange={eventTypesHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <CheckboxGroupElement
                            checkedList={checkedThirdpartyFilter}
                            list={thirdpartyFilterList}
                            onChange={thirdpartyFilterHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="row">{calendarList}</div>
        </div>
    </div>
}

export default Calendar
