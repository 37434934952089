import React from 'react'
import {IconProps} from '../../store/types'

const LoginIcon = (props: IconProps) => {
    return <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.13 20.5c-.59.58-1.38.9-2.2.9h-3.71a1 1 0 1 1 0-2h3.7c.3 0 .59-.12.8-.33.2-.2.32-.48.32-.77V5.7c0-.29-.11-.57-.32-.77-.21-.21-.5-.33-.8-.33h-3.7a1 1 0 1 1 0-2h3.7a3.13 3.13 0 0 1 3.12 3.1v12.6a3.1 3.1 0 0 1-.91 2.2Z"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.68 6.48a1 1 0 0 1 1.41.03l4.59 4.8a1 1 0 0 1 0 1.38l-4.59 4.8a1 1 0 0 1-1.44-1.38L12.62 13H2.96a1 1 0 1 1 0-2h9.66L9.65 7.9a1 1 0 0 1 .03-1.42Z"></path>
    </svg>
}

export default LoginIcon
